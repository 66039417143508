import React, { useState, useEffect, useRef } from 'react';
import "./css/sermons.css"
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import { domain } from './Domain';
import useFetch from './FetctData';
import DynamicMetaTags from './DynamicMetaTags';
import logo from './images/aim_logo.jpeg'
import AudioPlayer from './AudioPlayer/AudioPlayer';
import { Link } from 'react-router-dom';
import { UpcomingSkeleton } from './MainTwo';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
}

function Sermons() {
    const audioRef = useRef(null);
    const [events, setEvents] = useState([])
    const [loading, setLoading] = useState(true)
    const [ spinner, setSpinner ] = useState(true);
    const [sermons, setSermons] = useState([])
    const [loadingSermons, setLoadingSermons] = useState(true)
    const [nowPlaying, setNowPlaying] = useState(null);
    const {data:categories, loadingCategories, errorCategories} = useFetch(`${domain}/sermon-categories?type=audio`);
    const [selectedCategory, setSelectedCategory] = useState("All Sermons")

    const pageTitle = "Christos Rhema Church | Audio Sermons"
    const metaDescription = "Listen to all our sermons and get filled with the love of the Lord"
    const imageUrl = logo

    async function fetchData(url) {
        const response = await fetch(url,{
            mode: "cors", // no-cors, *cors, same-origin,
            //credentials: 'include',
        });
        const jsonData = await response.json();
        return jsonData
    }

    const handleSelectedCategory = async (e) => {
        let slug = e.target.getAttribute('data-src');
        setSelectedCategory(e.target.getAttribute('name'));
        const sermonData = await fetchData(`${domain}/sermons/category/${slug}`);
        setSermons(sermonData)
    }

    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000);

        const fetchSermons = async () => {
            const sermonData = await fetchData(`${domain}/sermons`);
            setSermons(sermonData);
            setLoadingSermons(false)
        };
        const fetchEvents = async () => {
            const eventsData = await fetchData(`${domain}/events-random`);
            setEvents(eventsData);
            setLoading(false)
        };
        fetchSermons();
        fetchEvents();
    }, []);
    const emptyArray = [...Array(3)];

    const SermonsSkeleton = () => {
        return(
            <SkeletonTheme baseColor="#ddd3" highlightColor="#5555">
                <div className='audio-info d-flex align-items-center gap-2'>
                    <div className='w-50'>
                        <Skeleton height={150} width={"100%"}  count={1} />
                    </div>
                    <div className='w-50'>
                        <Skeleton height={10} width={"30%"} count={1} />
                        <Skeleton height={10} width={"100%"}  count={2} />
                        <Skeleton height={10} width={"30%"}  count={1} />
                    </div>
                </div>
                <div className='d-flex justify-content-between w-100'>
                    <div className='d-flex w-50'>
                        <Skeleton height={30} width={"40px"} count={1} />
                        <Skeleton height={30} width={"40px"} count={1} />
                        <Skeleton height={30} width={"40px"} count={1} />
                        <Skeleton height={30} width={"40px"} count={1} />
                        <Skeleton height={30} width={"40px"} count={1} />
                    </div>
                    <div className='d-flex gap-2 align-items-center w-50 justify-content-end'>
                        <Skeleton height={30} width={"40px"} count={1} />
                        <Skeleton height={10} width={"100px"} count={1} />
                    </div>
                </div>
                <div className='pt-4 pb-4'>
                    <Skeleton height={20} width={"30%"} count={1} />
                </div>
                {emptyArray.map(item=>(
                    <div key={item} className={`d-flex gap-4 justify-content-between border-bottom pb-2 mt-2 p-2 rounded'}`}>
                        <span><Skeleton height={30} width={"20px"} count={1} /></span>
                        <Link className='d-block text-decoration-none w-75 text-success fw-bold'>
                            <Skeleton height={10} className='m-0 p-0' width={"100%"} count={2} />
                        </Link>
                        <button className='btn btn-sm'>
                            <Skeleton height={30} width={"40px"} count={1} />
                        </button>
                    </div>
                ))}
            </SkeletonTheme>
        )
    }

    return (
        <>
            {spinner ? <Loader /> : 
                <div>
                    <DynamicMetaTags pageTitle = {pageTitle} metaDescription = {metaDescription} imageUrl={imageUrl}/>
                    <Navbar />
                    <div className="container row m-auto pt-4">
                        <div className="col-lg-7">
                            <div className='d-flex align-items-center justify-content-between'>
                                <h4 className='fs-5 fw-bold text-success'>CRC Audio Media Files</h4>
                                <div className="dropdown">
                                    <button className="btn btn-success dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Select category
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" data-src={'all-audios'} name={"All Categories"} onClick={handleSelectedCategory}>All Audios</a></li>
                                        {categories && (
                                            categories.map(data => (
                                                <li key={data.id} style={{cursor:"pointer"}}><a className="dropdown-item" data-src={data.slug} name={data.name} onClick={handleSelectedCategory}>{data.name}</a></li>
                                            ))
                                        )}
                                    </ul>
                                </div>
                            </div>
                            {
                                loadingSermons ? <SermonsSkeleton />
                                :
                                sermons && sermons.length > 0 && <AudioPlayer tracks={sermons} selectedCategory={selectedCategory}/>
                            }
                        </div>
                        <div className="col-lg-5 news">
                            <h2 className="fs-5 fw-bold pt-2 pb-2 text-danger">Our Past Events</h2>
                            {
                                loading ? emptyArray.map(item => <UpcomingSkeleton key={item}/>)
                                :
                                events.map((data, index) => (
                                    <Link to={"/details/"+data.slug} key={data.id} className={`d-flex flex-wrap text-decoration-none text-dark ${events.length -1 !== index && 'border-bottom'} pb-2 pt-2 align-items-center`}> 
                                        <div className='col-3'>
                                            <img src={domain+data.cover} className='w-100' alt='eventImage' />
                                        </div>
                                        <div className='ps-2 col-9'>
                                            <p style={{fontSize: "0.875rem"}} className='m-0 text-success fw-bold'>{data.name}</p>
                                            <p style={{fontSize: "0.775rem"}} className='m-0' dangerouslySetInnerHTML={{ __html: truncateString(data.details, 100) }}></p>
                                        </div>
                                        <small style={{fontSize: "0.775rem"}} className='text-muted'>{data.created_on}</small>
                                    </Link>
                                ))
                            }
                        </div>
                    </div>
                    <Footer />
                </div>
            }
        </>
    )
}

export default Sermons