import { domain } from '../Domain';
import React, { useState, useRef, useEffect } from 'react';
import DisplayTrack from './DisplayTrack';
import Controls from './Controls';
import ProgressBar from './ProgressBar';
import { Link } from 'react-router-dom';
import {
    IoPlaySharp,
    IoPauseSharp,
  } from 'react-icons/io5';

const AudioPlayer = ({tracks, selectedCategory}) => {
    const [trackIndex, setTrackIndex] = useState(0);
    const [currentTrack, setCurrentTrack] = useState(tracks[trackIndex]);
    const progressBarRef = useRef();
    const [timeProgress, setTimeProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    // reference
    const audioRef = useRef();
    const handleNext = () => {
        if (trackIndex >= tracks.length - 1) {
            setTrackIndex(0);
            setCurrentTrack(tracks[0]);
        } else {
            setTrackIndex((prev) => prev + 1);
            setCurrentTrack(tracks[trackIndex + 1]);
        }
    };
    return (
      <div className="audio-player">
        <DisplayTrack {...{ currentTrack, audioRef, setDuration, progressBarRef, handleNext, selectedCategory }}/>
        <Controls {...{ audioRef, progressBarRef, duration, setTimeProgress, tracks, trackIndex, setTrackIndex, setCurrentTrack, handleNext }} />
        <ProgressBar {...{ progressBarRef, audioRef, timeProgress, duration }} />
        <p className='fw-bold ps-2' style={{borderLeft: "5px solid red"}}>Player List Items</p>
        <ul className='playList'>
            {tracks && tracks.map((item, index)=>(
                <div key={index} className={`d-flex gap-4 justify-content-between border-bottom pb-2 mt-2 ${item.id === currentTrack.id && 'border p-2 rounded'}`}>
                    <span>{index + 1}</span>
                    <Link className='d-block text-decoration-none w-75 text-success fw-bold' onClick={()=> setCurrentTrack(tracks[index])}><span className='text-dark fw-bold'>{item.title} by</span> {item.authur}<br /><small className='text-muted fw-light'>{item.date}</small></Link>
                    <button className='btn btn-sm'>
                        {item.id === currentTrack.id ? <IoPauseSharp /> : <IoPlaySharp />}
                    </button>
                </div>
            ))}
        </ul>
      </div>
    );
  };

export default AudioPlayer;