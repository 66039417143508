import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Link, useNavigate } from 'react-router-dom'
import "./css/online.css"
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import { domain } from './Domain';
import useFetch from './FetctData';

function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
}
function OnlineChurch() {
    const [url, setUrl] = useState(`${domain}/videos-online-church`)
    const [ spinner, setSpinner ] = useState(true);
    const {data: sermons, loading, error} = useFetch(url);
    const [currentUrl, setCurrentUrl] = useState('');
    const [videoName, setVideoName] = useState('');
    const [isPlaying, setPlaying] = useState(false)
    const overlay = useRef();
    const navigate = useNavigate()
    // It will be executed before rendering

    const handleRegister = (e) => {
        overlay.current.style.display = "block";
    }
    const handleHide = (e) => {
        overlay.current.style.display = "none";
    }

    const loadVideo = (e) => {
        console.log(e.target.getAttribute('data-src'));
        setCurrentUrl(e.target.getAttribute('data-src'));
        setVideoName(e.target.getAttribute("name"));
        //alert(e.target.getAttribute('data-src'));
    }

    const stopVid = () => {
        setPlaying(false);
    }

    const handleLogOut = () => {
        const formData = new FormData();
        let user = JSON.parse(localStorage.getItem("user"));
        let token;
        if(user !== null){
            token = user['token']
        }else{
            return
        }
        formData.append("token",token)
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Token ${token}`,
            },
            credentials: "omit",
            body: formData
        };
        fetch(`${domain}/accounts/logout`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            console.log(data);
            if(data['message'] === 'logout successfull'){
                try {
                    localStorage.removeItem('user');
                    navigate('/')
                } catch (error) {
                    console.log(error);
                }
            }
        })
        .catch((err) => console.log(err))
    }

    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000);
        
    }, []);

    return (
        <>
            {spinner ? <Loader /> : 
                <div>
                    <Navbar />
                    <div className='container-lg'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <h1 className='headed text-success pt-3 fs-4'>Welcome to Our Onine Church</h1>
                            <button className='btn btn-danger' onClick={handleLogOut}>Logout</button>
                        </div>
                        <p className='lead fs-6'>Remained connected with Pr. David Omongole Through Our online Church Services</p>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <h2 className='headed text-success pt-3 fs-5'>Recent Prayer Recordings</h2>
                                {sermons && (
                                    sermons.map(data => (
                                        <div key={data.id} className='d-flex justify-content-between align-items-center border-bottom pb-2 vids pt-2'>
                                            <img src={domain+data.cover} data-src={data.link} className='flex-grow-1 w-25 me-3 rounded' style={{height:"100px", objectFit:"cover",objectPosition:"center"}} alt='tube_poster' name={data.name} data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={loadVideo}/>
                                            <div className='flex-grow-1 w-50'>
                                                <h4 className='lead fs-6 p-0 m-0 fw-bold fg'>{data.name}</h4>
                                                <p className='text-muted p-0 m-0 lead fs-6'>{truncateString(data.description, 100)}</p>
                                                <small>posted on {new Date(data.created_at).toLocaleString()}</small>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                            <div className='col-lg-7 messages'>
                                <h2 className='text-success fw-bold headed fs-5'>Attend church Service</h2>
                                <Link className='btn btn-outline-danger' to={'/online-church-stream'}>Attend Church Service</Link>
                                <h2 className='fg fw-bold headed fs-5 pt-2'>Church Notifications & Messages</h2>
                                <div className='border-bottom pb-2 d-none'>
                                    <h4 className='fs-6 fw-bold text-success'>Name of the notification</h4>
                                    <p className='lead m-0' style={{fontSize: "0.875rem"}}>Lorem Ipsum is simply dummy text of the the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book... </p>
                                    <div style={{textAlign: "right"}}><small className='text-muted'>posted on: 12th / jan/ 2020</small></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='overlay' ref={overlay}>
                        <div className='inner2'>
                            <div className='d-flex justify-content-end'>
                                <p style={{cursor:"pointer"}} onClick={() => handleHide()}><i className="fa-regular fa-circle-xmark fa-2x"></i></p>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="staticBackdropLabel">{videoName}</h1>
                                    <button type="button" className="btn-close" onClick={stopVid} data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body m-0 p-0">
                                <ReactPlayer
                                    className='react-player'
                                    id="react-player"
                                    //light={<img src='images/network.png' width="100%" alt='Thumbnail' />}
                                    url={currentUrl}
                                    playing={isPlaying}
                                    width='100%'
                                    height='315px'
                                    controls={true}
                                    onPlay={() => setPlaying(true)}
                                    onPause={() => setPlaying(false)}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            }
        </>
  )
}

export default OnlineChurch