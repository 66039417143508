import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../App';

const ProtectedRoute = () => {
    //let {user, setUser} = useContext(UserContext); // determine if authorized, from context or however you're doing it
    let user = JSON.parse(localStorage.getItem("user"));
    let token;
    if(user !== null){
        token = user['token']
    }
    return token ? <Outlet /> : <Navigate to="/accounts/login" />;
}
export default ProtectedRoute