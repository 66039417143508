import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
//import "./css/sermonVideo.css"
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import { domain } from './Domain';
import DynamicMetaTags from './DynamicMetaTags';
import logo from './images/aim_logo.jpeg'
//import ReactPlayer from 'react-player/lazy'

function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
}

function Ministy(props){

    return(
        <div className="col">
            <div className="card">
                <img src={props.cover} className="card-img-top" alt="ministry"/>
                <div className="card-body">
                    <h5 className="card-title fw-bold">{props.name}</h5>
                    <p className="card-text lead fs-6 text-muted" dangerouslySetInnerHTML={{ __html: props.details }}></p>
                    <Link to={"/details/"+props.slug} className="text-danger fw-bold text-decoration-none">Read More</Link>
                </div>
            </div>
        </div>
    )
}

function SingleEventCategory() {
    const [single, setSingle] = useState([]);
    const [ spinner, setSpinner ] = useState(true);
    const [ category, setCategory ] = useState('');

    const { name } = useParams();
    // It will be executed before rendering

    const pageTitle = `CRC Events | ${name}`
    const [metaDescription, setMetaDesciption] = useState()
    const imageUrl = logo

    async function fetchData(slug) {
        const response = await fetch(`${domain}/all-events/${slug}`,{
            mode: "cors", // no-cors, *cors, same-origin,
            //credentials: 'include',
        });
        const jsonData = await response.json();
        //console.log(jsonData);

        //setMetaDesciption(truncateString(jsonData['details'], 60))
        setSingle(jsonData);
    }
      
    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000);
       /*get the last segment of the route
        var parts = window.location.href.split('/');
        var lastSegment = parts.pop() || parts.pop();  // handle potential trailing slash
        
        */fetchData(name);
        setCategory(localStorage.getItem("category"));
    }, []);

    return (
        <>  
            {spinner ? <Loader /> : 
                <div>
                    <DynamicMetaTags pageTitle = {pageTitle} metaDescription = {metaDescription} imageUrl={imageUrl}/>
                    <Navbar />
                    <div className="ministries bg-body-tertiary">
                        <div className='container-lg m-auto'>
                            <h5 className="text-danger pt-4">Events</h5>
                            <h1 className="headed fs-3 fw-bold pb-2">{category}</h1>
                        </div>
                        <div className="row row-cols-lg-3 g-2 m-auto container" style={{paddingBottom: "80px"}}>
                            {single.length > 0 && (
                                single.map(data => (
                                    <Ministy 
                                        key={data.id} 
                                        name={data.name}
                                        slug={data.slug}
                                        cover = {domain+data.cover}
                                        details = {truncateString(data.details, 100)} />
                                ))
                            )}
                        </div>
                    </div>
                    <Footer />
                </div>
            }
        </>
    )
}

export default SingleEventCategory