import React, {useEffect, useState} from 'react'
import "./css/maintwo.css"
import { domain } from './Domain';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
}
const emptyArray = [...Array(3)];

export const UpcomingSkeleton = () => {
    return(
        <SkeletonTheme baseColor="#ddd3" highlightColor="#5555">
            <div className='d-flex'>
                <div className='col-3'>
                    <Skeleton height={100} width={"100%"}  count={1} />
                </div>
                <div className='ps-2 col-6'>
                    <Skeleton height={10} width={"30%"} count={1} />
                    <Skeleton height={10} width={"100%"}  count={2} />
                    <Skeleton height={10} width={"30%"}  count={1} />
                </div>
            </div>
        </SkeletonTheme>
    )
}

const CarouselSkeleton = () => {
    return(
        <SkeletonTheme baseColor="#ddd3" highlightColor="#5555">
            <Carousel showArrows={true} autoPlay={true} infiniteLoop = {true} showThumbs = {true} thumbWidth={80} swipeable = {true} className='col-lg-8 col-md-8' >
                {
                    emptyArray.map(data => (
                        <Link>
                            <Skeleton height={300} width={"100%"} count={1} />
                            <p className="legend">
                                <Skeleton height={10} width={"100%"} count={4} />
                                <Skeleton height={10} width={"20%"}  count={1} />
                            </p>
                        </Link>
                    )
                )}  
            </Carousel>
        </SkeletonTheme>
    )
}

function MainTwo() {
    const [events, setEvents] = useState([])
    const [loadingEvents, setLoadingEvents] = useState(true)
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [loadingUpcomingEvents, setLoadingUpcomingEvents] = useState(true)
    // It will be executed before rendering
    async function fetchData(url) {
        const response = await fetch(url,{
            mode: "cors", // no-cors, *cors, same-origin,
            //credentials: 'include',
        });
        const jsonData = await response.json();
        return jsonData;
    }
      
    useEffect(() => {
        const fetchEvents = async () => {
            const eventsData = await fetchData(`${domain}/events-random`);
            setEvents(eventsData);
            setLoadingUpcomingEvents(false)
        };
        const fetchUpcomingEvents = async () => {
            const eventsData = await fetchData(`${domain}/upcoming-events`);
            setUpcomingEvents(eventsData);
            setLoadingEvents(false)
        };
    
        fetchEvents();
        fetchUpcomingEvents();
    }, []);
  return (
    <div className="ministries bg-body-tertiary ">
        <h5 className="text-danger text-center pt-4">Events</h5>
        <h1 className="headed text-center pb-0">Our Latest Events</h1>
        <div className="row g-4 m-auto container">
            <Carousel showArrows={true} autoPlay={true} infiniteLoop = {true} showThumbs = {true} thumbWidth={80} swipeable = {true} className='col-lg-8 col-md-8' >
                {
                    loadingEvents ?  <CarouselSkeleton />
                    :
                    events.length > 0 && (
                        events.map(data => (
                            <Link to={"/details/"+data.slug}>
                                <img src={domain+data.cover} />
                                <p className="legend" dangerouslySetInnerHTML={{ __html: truncateString(data.details, 300) }}></p>
                            </Link>
                        ))
                    )
                }  
            </Carousel>
            <div className='col-lg-4 col-md-4'>
                <h2 className="fs-5 fw-bold pt-2 pb-2">Upcoming Events</h2>
                    {loadingUpcomingEvents ?
                        emptyArray.map(item=>(
                            <UpcomingSkeleton key={item}/>
                        ))
                    : 
                    upcomingEvents.map((data, index) => (
                        <Link to={"/details/"+data.slug} key={data.id} className={`d-flex flex-wrap text-decoration-none text-dark ${upcomingEvents.length -1 !== index && 'border-bottom'} pb-2 pt-2 align-items-center`}> 
                            <div className='col-3'>
                                <img src={domain+data.cover} className='w-100' alt='eventImage' />
                            </div>
                            <div className='ps-2 col-9'>
                                <p style={{fontSize: "0.875rem"}} className='m-0 text-success fw-bold'>{data.name}</p>
                                <p style={{fontSize: "0.775rem"}} className='m-0' dangerouslySetInnerHTML={{ __html: truncateString(data.details, 60) }}></p>
                            </div>
                            <small style={{fontSize: "0.775rem"}} className='text-muted'>{data.created_on}</small>
                        </Link>
                        ))
                    }
            </div>
        </div>
    </div>
  )
}

export default MainTwo
/*
function Ministy(props){
    return(
        <div className="col">
            <div className="card" id='card'>
                <img src={props.cover} className="card-img-top" alt="ministry"/>
                <div className="card-body">
                    <h5 className="card-title fw-bold">{props.name}</h5>
                    <p className="card-text lead fs-6 text-muted" dangerouslySetInnerHTML={{ __html: props.details }}></p>
                    <Link to={"/details/"+props.slug} className="text-danger fw-bold text-decoration-none">Read More</Link>
                </div>
            </div>
        </div>
    )
}
<div className="d-none row row-cols-lg-3 row-cols-1 row-cols-md-2 g-4 m-auto container" style={{paddingBottom: "80px"}}>
    {events.length > 0 && (
        events.map(data => (
            <Ministy 
                key={data.id} 
                name={data.name}
                slug={data.slug}
                cover = {domain+data.cover}
                details = {truncateString(data.details, 180)} />
        ))
    )}
</div>
*/