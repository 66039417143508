import React from 'react'

function DetailsPrayerrequest({prayerRequest, handleHide}) {
    return(
        <div key={prayerRequest.id} className='inner'>
            <small>From:</small>
            <div className='d-flex justify-content-end'>
                <p style={{cursor:"pointer"}} onClick={() => handleHide()}><i className="fa-regular fa-circle-xmark fa-2x"></i></p>
            </div>
            <h2 className='fw-bolder'>{prayerRequest.name}</h2>
            <h4 className='pt-3' style={{fontFamily:"Playfair"}}>Prayer Request</h4>
            <p className='fw-light' dangerouslySetInnerHTML={{ __html: prayerRequest.request }}></p>
            <hr />
            <small>sent on {prayerRequest.created_at}</small>
        </div>
    )
}

export default DetailsPrayerrequest