import React, {useEffect, useState} from 'react'
import { domain } from './Domain';
import { Link } from 'react-router-dom'
import "./css/footer.css"


function Footer() {
    const [ subscribe, setSubscribing ] = useState(false);

    const subscribeEmail = () => {
        setSubscribing(true);
        let form = document.getElementById("subscription");
        let formData = new FormData(form);
        let email = formData.get("email");
        if(email.length === 0){
            document.getElementById("response").innerHTML = "Please Enter A Valid Email";
            setTimeout(function(){
                document.getElementById("response").innerHTML="";
            }, 5000);
        }else{
            const requestOptions = {
                method: 'POST',
                credentials: "omit",
                body: formData
            };
            fetch(`${domain}/subscriptions`, requestOptions)
            .then((res) => res.json())
            .then((data) => {
                //console.log(data);
                form.reset();
                setSubscribing(false);
                if(data['email'] !='subscriptions with this email already exists.'){
                    document.getElementById("response").innerHTML = data['email']+ " subscribed successfully!";
                }else{
                    document.getElementById("response").innerHTML = data['email'];
                }
                setTimeout(function(){
                    document.getElementById("response").innerHTML="";
                }, 5000);
            })
            .catch((err) => console.log(err))
        }
    }
  return (
    <div className='footer'>
        <div className='row row-cols-1 row-cols-lg-3 container-lg m-auto text-light'>
                <div className='col-lg-2'>
                    <img src='../images/aim_logo.jpeg' className='bg-white' alt='logo' />
                    <p className="lead fs-6">Agapao International Ministries.</p>
                    <p className='fs-6 m-0'>Christos Rhema Church</p>
                </div>
                <div className='col-lg-7'>
                    <h1 className='fs-4 fw-bold'>Contact Us</h1>
                    <div className='row row-cols-1 row-cols-lg-2'>
                        <div className='col'>
                            <h2 className='fs-5 pt-4'>Head Office</h2>
                            <div className='d-flex ps-4 align-items-center'>
                                <p><i className="fa-solid fa-house"></i></p>
                                <p className='ms-2 lead fs-6' >Plot 2266, Christos Rhema Church,    15 Kaminanda Ln, Kampala,</p>
                            </div>
                            <div className='d-flex ps-4'>
                                <p><i className="fa-solid fa-mobile-screen-button"></i></p>
                                <p className='ms-2 lead fs-6'>0393206175</p>
                            </div>
                            <div className='d-flex ps-4 align-items-center'>
                                <p><i className="fa-solid fa-mobile-screen-button"></i></p>
                                <p>
                                    <a className='text-light ms-2 lead fs-6 p-0 m-0' href='mailto:info@christosrhema.org'>info@christosrhema.org</a><br />
                                    <a className='text-light ms-2 lead fs-6 p-0 m-0' href='mailto:christosrhemachurch@gmail.com'>christosrhemachurch@gmail.com</a>
                                </p>
                            </div>
                        </div>
                        <div className='col'>
                            <h2 className='fs-5 pt-4'>Quick Links</h2>
                            <Link className='d-flex ps-4 m-0' to={'/staff'}>
                                <p><i className="fa-solid fa-users"></i></p>
                                <p className='ms-2 lead fs-6'>About Us</p>
                            </Link>
                            <Link className='d-flex ps-4 m-0' to={'/contact-us'}>
                                <p><i className="fa-solid fa-headset"></i></p>
                                <p className='ms-2 lead fs-6'>Contact Us</p>
                            </Link>
                            <Link className='d-flex ps-4 m-0' to={'/questions'}>
                                <p><i className="fa-solid fa-mobile-screen-button"></i></p>
                                <p className='ms-2 lead fs-6'>Having a question ?</p>
                            </Link>
                            <Link className='d-flex ps-4 m-0' to={'/give'}>
                                <p><i className="fa-solid fa-image"></i></p>
                                <p className='ms-2 lead fs-6'>Give to the lord</p>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='col-lg-3'>
                    <h1 className='fs-4 fw-bold'>Subscribe & Follow</h1>
                    <p className="fs-6 lead">Subscribe to our News Letter.</p>
                    <form action="" id='subscription'>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" name='email' placeholder="Email Address" aria-label="Email Address"
                                aria-describedby="button-addon2"/>
                            <button className="btn btn-danger" onClick={subscribeEmail} type="button" id="button-addon2">subscribe</button>
                        </div>
                        <p id='response' className='text-white fw-bolder' style={{fontSize: "0.875rem"}}></p>
                    </form>
                    <h1 className='fs-6 fw-bold'>Follow Us</h1>
                    <a href="https://wa.me/256706905656?text=greetings" className='d-block text-light'><i className="fa-brands fa-whatsapp text-light"></i> whatsapp</a>
                    <a href="https://www.facebook.com/christosrhemachurch" className='d-block text-light'><i className="fa-brands fa-facebook text-light"></i> facebook</a>
                    <a href="https://twitter.com/RhemaChristos" className='d-block text-light'><i className="fa-brands fa-twitter text-light"></i> twitter</a>
                    <a href="https://instagram.com/christos_rhema_church?igshid=NTA5ZTk1NTc=" className='d-block text-light'><i className="fa-brands fa-instagram text-light"></i> instagram</a>
                </div>
            </div>
    </div>
  )
}

export default Footer