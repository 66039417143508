import React, { useState, useEffect } from 'react';
import "./css/question.css"
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import useFetch from './FetctData';
import { domain } from './Domain';
import { Link } from 'react-router-dom';
import DynamicMetaTags from './DynamicMetaTags';
import logo from './images/aim_logo.jpeg'

function Questions() {
    const [dataPost, setDataPost] = useState(null);
    const [loadingPost, setLoadingPost] = useState(false);
    const [errorPost, setErrorPost] = useState(null);

    const pageTitle = "CRC Questions | Ask Pr. Omongole David Omongole"
    const metaDescription = "description"
    const imageUrl = logo

    const [ spinner, setSpinner ] = useState(true);
    const [myComponent, setComponent] = useState(false);
    const {data: question, loading, error, refresh, setRefresh} = useFetch(`${domain}/questions`);
    const {data: answers, loading: loadingAnswers, error: errorAnswers, refresh:refreshAnswers, setRefresh:setRefreshAnswers} = useFetch(`${domain}/answers`);

    function componentQuestions() {
        setComponent(false);
        setRefresh(!refresh);
    }
    function componentAnswers() {
        setComponent(true);
        setRefreshAnswers(!refreshAnswers);
    }

    function QuestionComp(props){
        return(
            <>
                <div className='row m-auto questions'>
                    <div className='col-lg-3 col-md-3 text-lg-center d-flex align-items-center d-lg-block d-md-block'>
                        <img className='img-thumbnail me-2' src="/images/bg.jpeg" alt='user_image'/>
                        <div>
                            <figcaption className='text-muted'>{props.anonymous ? props.name : 'annonymous'}</figcaption>
                            <small className='text-muted date'>{props.created_at}</small>
                        </div>
                    </div>
                    <div className='col-lg-9 col-md-3'>
                        <small className='text-danger fw-bold'>Question</small>
                        <p className='lead qa'>{props.question}</p>
                        <small className='text-success fw-bold'>Answer</small>
                        <p className='lead qa'>{props.answer}</p>
                        <p className='text-danger answeredby'>answered by {props.answered_by} {props.answered_at}</p>
                    </div>
                </div>
            </>
        )
    }
    function QuestionCompPending(props){
        return(
            <>
                <div className='row m-auto questions'>
                    <div className='col-lg-3 col-md-3 text-lg-center d-flex align-items-center d-lg-block d-md-block'>
                        <img className='img-thumbnail me-2' src="/images/bg.jpeg" alt='user_image'/>
                        <div>
                            <figcaption className='text-muted'>{props.anonymous ? props.name : 'annonymous'}</figcaption>
                            <small className='text-muted date'>{props.created_at}</small>
                        </div>
                    </div>
                    <div className='col-lg-9 col-md-3'>
                        <small className='text-danger fw-bold'>Question</small>
                        <p className='lead qa'>{props.question}</p>
                        <small className='text-success fw-bold'>Answer</small>
                        <p className='lead qa'>{props.answer}</p>
                    </div>
                </div>
            </>
        )
    }

    async function postData(data) {
        setLoadingPost(true);
        fetch(`${domain}/questions`,{
            method: "POST",
            mode: "cors", // no-cors, *cors, same-origin,
            //credentials: 'include',
            body: data,
        })
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            setDataPost(data);
            setLoadingPost(false);
            setErrorPost(null);

            //if post completes reload the questions 
            setRefresh(!refresh);
            setRefreshAnswers(!refreshAnswers);
        })
        .catch(err =>{
            setErrorPost(err.message);
            setLoadingPost(false);
        })
    }

    function handleForm(){
        let form = document.getElementById("questionForm");
        form.onsubmit = function(event){
            event.preventDefault()
            let form_data = new FormData(form);
            let publish = form_data.get("subscribe");
            if(publish === null){
                publish = true;
            }else{
                publish = false;
            }
            let recieved = {
                "name":form_data.get("name"),
                "email":form_data.get("email"),
                "tel":form_data.get("contact"),
                "message":form_data.get("message"),
                "publish":publish
            }
            let sendingData = new FormData()
            sendingData.append("name",recieved['name']);
            sendingData.append("email",recieved['email']);
            sendingData.append("phone",recieved['tel']);
            sendingData.append("qtn",recieved['message']);
            sendingData.append("anonymous",recieved['publish']);
            postData(sendingData);
            form.reset();
        }
    }

    function Answered(){
        return(
            <>
            {answers && (
                answers.map(data => (
                    <QuestionComp 
                        name={data.name}
                        question={data.question}
                        anonymous={data.anonymous}
                        answer={data.answer}
                        created_at={data.created_on}
                        answered_at={data.answered_on}
                        answered_by={data.answered_by}
                    />
                ))
            )} 
            </>
        )
    }
    function Pending(){
        return(
            <>
            {question && (
                question.map(data => (
                    <QuestionCompPending 
                        name={data.name}
                        question={data.question}
                        anonymous={data.anonymous}
                        answer="pending ..."
                        created_at={data.created_on}
                    />
                ))
            )} 
            </>
        )
    }

    useEffect(() => {
        setTimeout(() => setSpinner(false), 500);
    }, [myComponent]);

  return (
    <>
    {spinner ? <Loader /> : 
        <div>
            <DynamicMetaTags pageTitle = {pageTitle} metaDescription = {metaDescription} imageUrl={imageUrl}/>
            <Navbar />
            <div className='body-questions'>
                <div className='row container-lg g-4 pb-4 m-auto row-cols-lg-2'>
                    <div className='col-lg-5'>
                        <div className='position-sticky top-0'>
                            <h1 className='fs-4 fw-bold pt-2 pb-2'><span className='fs-5 fg'>Ask Us any</span> <span className='fs-3 text-success'>Question</span></h1>
                            <form id='questionForm'>
                                <div className="form-floating mb-3">
                                    <input type="text" name="name" className="form-control" id="floatingInput" placeholder="Enter your name"/>
                                    <label for="floatingInput" className="text-muted fs-6">Enter your name</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="email" name='email' className="form-control" id="floatingInput" placeholder="Enter your email"/>
                                    <label for="floatingInput" className="text-muted fs-6">Enter your email</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="tel" name='contact' className="form-control" id="floatingInput" placeholder="Enter your phone number"/>
                                    <label for="floatingInput" className="text-muted fs-6">Enter your phone number</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <textarea name="message" className="form-control"
                                        placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                                    <label for="floatingTextarea" className="text-muted fs-6">Type Your Question Here</label>
                                </div>
                                <input type="checkbox" name="subscribe"/> <span className="text-muted fs-6"> post as annonymous</span>
                                {! loadingPost && <button className="btn btn-success text-white w-100 p-2 mt-2" onClick={handleForm}>Submit Your Question <i class="fa-solid fa-right-to-bracket"></i></button>}
                                {loadingPost && <button className="btn btn-success text-white w-100 p-2 mt-2" disabled>Submiting your question <i class="fa-solid fa-right-to-bracket"></i></button>}
                            </form>
                        </div>
                    </div>
                    <div className='col-lg-7'>
                        <div>
                            <h1 className='fs-4 fw-bold pt-2 pb-2 fg'>Recently <span className='fs-2 text-danger'>Asked</span> Questions</h1>
                            <div id='question_answer_div'>
                                <button className='btn btn-danger text-white me-4 mb-3' onClick={componentQuestions}>Unanswered Qtns</button>
                                <button className='btn btn-success mb-3' onClick={componentAnswers}>Answered Qtns</button>
                                {myComponent ? 
                                <>
                                    {error && <p>{error}</p>}
                                    {loading && <p>Loading data ....</p>}
                                    <Answered />
                                </>
                                :
                                <>
                                    {errorAnswers && <p>{errorAnswers}</p>}
                                    {loadingAnswers && <p>Loading data ....</p>}
                                    <Pending/>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    }
    </>
  )
}

export default Questions