import React, {useEffect, useState } from 'react';
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import DynamicMetaTags from './DynamicMetaTags';
import logo from './images/aim_logo.jpeg'


function LoveHands() {
    const [ spinner, setSpinner ] = useState(true);
    const pageTitle = "CRC Ministries | Love Hands"
    const metaDescription = "description"
    const imageUrl = logo

    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000);
    }, []);

    return (
        <>
        {spinner ? <Loader /> : 
            <div>
                <DynamicMetaTags pageTitle = {pageTitle} metaDescription = {metaDescription} imageUrl={imageUrl}/>
                <Navbar />
                <div className='container-lg'>
                    <h1 className='fs-4 fw-bold pt-4 pb-2 fg'>The Helps  <span className='fs-3 text-danger'>Directorate</span></h1>
                    <p className='lead fs-6'>The Helps Directorate operates with a purpose of reaching out to individuals in communities who need long term assistance in order to live a better life. </p>
                    <p className='lead fs-6'>The basis of the operations of the directorate stem from Romans 12:13 <q className='fw-bold text-success'>When God’s people are in need, be ready to help them. Always be eager to practice hospitality. </q>(NLT) Paul wrote generally that Christians must be devoted to each other. They should show brotherly affection and honour to one another. The Helps Directorate encourages congregants within the ministry to practise this biblical principle of Christ’s love.</p>
                    <p className='lead fs-6'>The activities of the department rotate around transforming individuals from a level of disadvantage to a level of advantage. This involves strategically positioning these individuals, preparing and shaping them to fit into the different societies with an outcome of contributing to the overall transformation of society.</p>
                </div>
                <Footer />
            </div>
        }
        </>
    )
}

export default LoveHands