import React, { useState, useEffect, useContext, useRef } from 'react';
import "./css/loginRegister.css"
import Loader from './Loader';
import icon from './images/aim_logo.jpeg';
import { domain } from './Domain';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { UserContext } from '../App';

export function ForgotPassword(){
    const [email, setEmail] = useState("");
    const [isPending, setIsPending] = useState(false);
    const regRefSuccess = useRef();

    function validateForm() {
        return email.length > 0;
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsPending(true);
        regRefSuccess.current.style.display = "block";
        regRefSuccess.current.textContent = "Sending registration data ..."
        let form = e.target;
        let data = new FormData(form);
        const requestOptions = {
            method: 'POST',
            cors: "cors",
            body: data
        };
        fetch(`${domain}/accounts/password-reset`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            //console.log(data);
            form.reset();
            regRefSuccess.current.textContent = data['message']
            setTimeout(function(){
                regRefSuccess.current.textContent = '';
                regRefSuccess.current.style.display = "none";
            },5000);
            setIsPending(false);
        })
        .catch((err) => console.log(err))
    }

    return(
        <div className='forgotPassword'>
            <div className='p-2'>
                <i class="fa-solid d-none fa-user-tie fa-2x"></i>
                <h1 className='fs-5 pt-2 lead fw-bold fg'>Forgot Password</h1>
                <p className='lead fs-6'>Please provide us with a valid username or email Address so as to send you reset instructions</p>
            </div>
            <p ref={regRefSuccess} className='text-success' style={{display:"none"}}></p>
            <form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                    <span class="input-group-text"><i class="fa-solid fa-user-secret"></i></span>
                    <input type="text" name='username' class="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Username" aria-label="Username"/>
                </div>
                
                {! isPending && <button className='btn btn-success w-100 mb-3' type="submit" disabled={!validateForm()}>Reset Password <i class="fa-solid fa-right-to-bracket"></i></button>}
                {isPending && <button className='btn btn-success w-100 mb-3' disabled>Requesting Reset Instructions ... </button>}
            </form>
        </div>
    )
}

function Login(){
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const {user, setUser} = useContext(UserContext)
    const navigate = useNavigate();

    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();
        let formData = new FormData();
        formData.append("username",email);
        formData.append("password",password);
        const requestOptions = {
            method: 'POST',
            credentials: "omit",
            body: formData
        };
        fetch(`${domain}/accounts/login`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            console.log(data);
            console.log("we are now setting the local storage value")
            if(data.token){
                setUser(data);
                localStorage.setItem('user', JSON.stringify(data));
                if(data['user_info']['is_super'] === true){
                    navigate('/dashboard');
                }else{
                    navigate('/online-church');
                }
                
            }
            //setToken(data.token);
            //form.reset();
        })
        .catch((err) => console.log(err));
    }

    return(
        <div>
            <div className='text-center p-2 d-none'>
                <i class="fa-solid fa-user-tie fa-2x"></i>
                <h1 className='fs-5 pt-2 lead fw-bold fg'>User Login</h1>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                    <span class="input-group-text"><i class="fa-solid fa-user-secret"></i></span>
                    <input type="text" class="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Username" aria-label="Username"/>
                </div>
                <div className="input-group mb-3">
                    <span class="input-group-text"><i class="fa-solid fa-lock"></i></span>
                    <input type="password" class="form-control" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" aria-label="Password"/>
                </div>
                <button className='btn btn-success w-100 mb-3' type="submit" disabled={!validateForm()}>Login <i class="fa-solid fa-right-to-bracket"></i></button>
                <div className='d-flex justify-content-end'>
                    <small className='pe-3'><Link to={"/accounts/forgot-password"} className='text-danger fw-bold' >Forgot password?</Link> </small>
                    <small><Link to={"/accounts/register"} className='fw-bold' >Create account</Link></small>
                </div>
                <hr />
                <div className='d-flex justify-content-between border rounded p-3'>
                    <Link><small>Terms & conditons</small></Link>
                    <Link><small>Privacy Policy</small></Link>
                    <Link><small>Security</small></Link>
                </div>
            </form>
        </div>
    )
}



function Accounts({setToken}) {
    const [ spinner, setSpinner ] = useState(true);

    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000);
    }, []);
    return (
        <>
        {spinner ? <Loader /> : 
            <div>
                <div className='loginRegister'>
                    <img src={icon} />
                    <h1 className='fs-5 pt-4 pb-2 lead fw-bold text-danger'>Login To CRC Account</h1>
                    <div>
                        <Login setToken = {setToken}/>
                    </div>
                </div>
            </div>
        }
        </>
    )
}
export default Accounts