//import logo from './logo.svg';
import {useState, createContext, useEffect} from 'react'
import { Routes, Route } from 'react-router-dom';
import './App.css';
import EntryPage from './components/EntryPage';
import Sermons from './components/Sermons';
import AnnualEvents from './components/AnnualEvents';
import WeeklyEvents from './components/WeeklyEvents';
import BranchChurches from './components/BranchChurches';
import Contact from './components/Contact';
import Staff from './components/Staff';
import Give from './components/Give';
import Questions from './components/Questions';
import RhemaCollege from './components/RhemaCollege';
import RegistrationForm from './components/RegistrationForm';
import SermonsVideo from './components/SermonsVideo';
import EventDetails from './components/EventDetails';
import SingleEventCategory from './components/SingleEventCategory';
import OnlineChurch from './components/OnlineChurch';
import Accounts from './components/Accounts';
import { ForgotPassword } from './components/Accounts';
import NotFound from './NotFound';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import WomanOfSubstance from './components/WomanOfSubstance';
import LoveHands from './components/LoveHands';
import ProtectedRoute from './components/ProtectedRoute';
import OnlineChurchRecieverStream from './components/OnlineChurchRecieverStream';
import PasswordResetForm from './components/PasswordResetForm';

export const UserContext = createContext(null)
function App() {
  const [user, setUser] = useState(null)
  useEffect(()=>{
    try {
      let user = JSON.parse(localStorage.getItem("user"))
      setUser(user)
    } catch (error) {
      console.log(error)
    }
    
  },[])
  return (
    <div className="App">
      <UserContext.Provider value={{user, setUser}}>
        <Routes>
            <Route path='/' element={<EntryPage />}></Route>
            <Route path='/questions' element={<Questions />}></Route>
            <Route path='/sermons-audio' element={<Sermons />}></Route>
            <Route path='/sermons-video' element={<SermonsVideo />}></Route>
            <Route path='/annual-events' element={<AnnualEvents />}></Route>
            <Route path='/weekly-events' element={<WeeklyEvents />}></Route>
            <Route path="/weekly-events/:name" element={<SingleEventCategory />}></Route>
            <Route path="/annual-events/:name" element={<SingleEventCategory />}></Route>
            <Route path='/branch-churches' element={<BranchChurches />}></Route>
            <Route path='/contact-us' element={<Contact />}></Route>
            <Route path='/staff' element={<Staff />}></Route>
            <Route path='/give' element={<Give />}></Route>
            <Route path='/rhema-collge' element={<RhemaCollege />}></Route>
            <Route path='/woman-of-substance' element={<WomanOfSubstance />}></Route>
            <Route path='/love-hands' element={<LoveHands />}></Route>
            <Route path='/rhema-collge/registration' element={<RegistrationForm />}></Route>
            <Route path="/details/:name" element={<EventDetails />}></Route>
            <Route path="/accounts/login" element={<Accounts />}></Route>
            <Route path="/accounts/register" element={<Register />}></Route>
            <Route path="/accounts/forgot-password" element={<ForgotPassword />}></Route>
            <Route path="/accounts/password-reset/:slug" element={<PasswordResetForm />}></Route>
            <Route path='/*' element={<NotFound />}></Route>
            <Route element={<ProtectedRoute />}>
              <Route path='/dashboard' element={<Dashboard />}></Route>
              <Route path="/online-church" element={<OnlineChurch />}></Route>
              <Route path="/online-church-stream" element={<OnlineChurchRecieverStream />}></Route>
            </Route>
        </Routes>
      </UserContext.Provider>
    </div>
  );
}

function SuperUser({children}){
  return<>{children}</>
}

function NormalUser({children}){
  return<>{children}</>
}

export default App;
