import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import "./css/school.css"
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import DynamicMetaTags from './DynamicMetaTags';
import logo from './images/aim_logo.jpeg'


function RhemaCollege() {
    const [ spinner, setSpinner ] = useState(true);
    const pageTitle = "CRC Ministries | Rhema College"
    const metaDescription = "description"
    const imageUrl = logo

    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000);
        
    }, []);
    return (
        <>
        {spinner ? <Loader /> : 
            <div className='bgs'>
                <DynamicMetaTags pageTitle = {pageTitle} metaDescription = {metaDescription} imageUrl={imageUrl}/>
                <Navbar />
                <img className='w-100 schoolImage mb-2' src="/images/photo_2023-05-21_18-50-41.jpg" alt='rhema-school-banner'/>
                <div className='container-lg intoduction position-relative'>
                    <div className='welcome text-center'>
                        <h1 className='fs-4 fw-bold pt-4 pb-2 text-success'>Welcome to<br /><span className='text-warning fs-3'>Rhema</span> <span className='text-danger fw-bold'>College</span> <span className='text-secondary'>Agapao International Ministries</span></h1>
                        <i class="fa-solid fa-quote-left fs-1 mt-2 mb-4 text-success"></i>
                        <p className='m-auto lead fs-6'>Rhema College is a directorate extension of <b style={{fontWeight:900}}>Christos Rhema Church</b> under the Ministry of AGAPAO International with the Vision of growing Christians into Maturity in Christ. The college is a unique spiritual academic institution which trains and equips ministers of the gospel of Christ to come to the unity of faith and the knowledge of the son of God therefore growing into maturity in Christ.</p>
                        <p className='m-auto lead fs-6 pt-4'>Over the years, Rhema College has given hundreds the opportunity to be tutored by the man of God Pastor Omongole David and many have had their lives and ministries transformed as result of the encounter they receive at the Rhema College.</p>
                        <p className='m-auto lead fs-6 pt-4'>The College offers a <b style={{fontWeight:900}}>one year course</b> of <b style={{fontWeight:900}}>Bible study classes</b>, with two semesters in it and each semester is covered by fourteen classes.</p>
                    </div>
                    <div className='text-center director'>
                        <img className='img-thumbnail rounded-circle schoolImage mb-2' src="/images/serunkuma-derrick.jpeg" alt='rhema-school-banner'/>    
                        <figcaption className='fw-bolder pt-0 fs-5'>Serunkuma Derrick</figcaption>
                        <p className='lead'>Director RC</p>
                        <Link className='btn btn-danger mb-4' to={'/contact-us'}>Contact Us</Link>
                    </div>
                </div>
                <div className='bg-light text-center pb-4'>
                    <h1 className='pt-4 fw-bolder fs-4 fg pb-4'><span className='fw-bolder'>Programs we offer</span></h1>
                    <div className='d-block d-md-flex programs flex-wrap justify-content-center'>
                        <div className='flex-grow-1 border rounded p-2'>
                            <i class="fa-solid fa-code-branch fs-2 pt-2 pb-2 text-danger"></i>
                            <hr />
                            <h2 className='fs-6 fw-bold w-50 m-auto text-muted pt-2 pb-2'> 	Branches</h2>
                            <p>Kampala, Jinjja, Soroti and China</p>
                            <h2 className='fs-6 fw-bold w-50 m-auto text-muted pt-2 pb-2'>Online Course</h2>
                            <p>is now available</p>
                        </div>
                        <div className='flex-grow-1 border rounded p-2'>
                            <i class="fa-solid fa-graduation-cap fs-2 pt-2 pb-2 text-danger"></i>
                            <hr />
                            <h2 className='fs-6 fw-bold w-50 m-auto text-muted pt-2 pb-2'>End of Course Award </h2>
                            <p>Certificate of Accomplishment</p>
                        </div>
                        <div className='flex-grow-1 border rounded p-2'>
                            <i class="fa-solid fa-money-check-dollar fs-2 pt-2 pb-2 text-danger"></i>
                            <hr />
                            <h2 className='fs-6 fw-bold w-50 m-auto text-muted pt-2 pb-2'>Tuition Structure </h2>
                            <p>students 30,000 shs per semester</p>
                            <p> working class 80,000 shs per semester</p>
                        </div>
                        <div className='flex-grow-1 border rounded p-2'>
                            <i class="fa-solid fa-school fs-2 pt-2 pb-2 text-danger"></i>
                            <hr />
                            <h2 className='fs-6 fw-bold w-50 m-auto text-muted pt-2 pb-2'>Study program</h2>
                            <p>Monday only from 17:30 - 20:30 E.A.T Across all the Church Branches</p>
                        </div>
                        <Link to="/rhema-collge/registration" className="btn btn-success mt-4 mb-4">Enroll Now <i class="fa-solid fa-right-to-bracket"></i></Link>
                    </div>
                </div>
                <Footer />
            </div>
        }
        </>
    )
}

export default RhemaCollege