import React from 'react'
import './css/account.css'
import { Link } from 'react-router-dom';

function Account() {
    function handleUserInfo(e){
        e.preventDefault();
        let formData = new FormData(document.querySelector("#profile"));
        alert("handle update of user information");
    }
  return (
    <div className='userProfile'>
        <form onSubmit={handleUserInfo} id='profile' className='row m-auto'>
            <div className='col-12'>
                <p className='lead text-muted'>To update your details, please change the fields and save the form upon completion. <br />Ensure to always keep your information up to date and confindetial.</p>
            </div>
            <div className='col-lg-6'>
                <label>Name</label>
                <div className='d-flex'>
                    <input type='text' name='name' placeholder='firstname' />
                    <input type='text' name='name' placeholder='lastname' />
                </div>
                <label>Email</label>
                <input type='email' name='email' placeholder='user email' />
                <label>Gender</label>
                <input type='radio' name='gender' value={'male'} /> male<br />
                <input type='radio' name='gender' value={'female'} /> female
                <label>Date of Birth</label>
                <input type='date' name='date'/>
            </div>
            <div className='col-lg-6'>
                <label>Address</label>
                <textarea placeholder='user address' rows={5} name='address'></textarea>
                <label>Contact</label>
                <input type='tel' name='tel' placeholder='phone number'/>
            </div>
            <div className='pt-2'>
                <button>Update User Info</button>
                <p className='pt-2 lead'>To change your account password, please click on the <Link className='text-decoration-none bg-white text-danger fw-bold'>change password</Link> to set your new account password</p>
            </div>
        </form>
    </div>
  )
}

export default Account