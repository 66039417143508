import React , {useState, useRef, useEffect} from 'react'
import "./css/dashboard.css"
import { Link, useNavigate } from 'react-router-dom'
import icon from './images/aim_logo.jpeg'
import useFetch from './FetctData'
import PrayerRequests from './PrayerRequests'
import QuestionsDashboard from './QuestionsDashboard'
import Notifications from './Notifications'
import Subscriptions from './Subscriptions'
import DetailsDashboard from './DetailsDashboard'
import OnlineChurchStream from './OnlineChurchStream'
import Account from './Account'
import { domain } from './Domain'


const InitialComponent = () => {
    const [details, setDetails] = useState(null);
    const {data: statistics, loading:stats_loading, error:error_stats, refresh:ref, setRefresh:setRefreshment} = useFetch(`${domain}/statistics`);
    const {data: questions, loading, error, refresh, setRefresh} = useFetch(`${domain}/questions`);
    const overlay = useRef();
    const [reload, setReload] = useState(false);

    const handleHide = (e) => {
        setDetails(null);
        overlay.current.style.display = "none";
        setReload(!reload);
        setRefresh(!refresh);
        setRefreshment(!ref);
        console.log(questions);
    }
    
    const handleDetails = (e) => {
        overlay.current.style.display = "block"
        //const filtered = questions.filter((question) => question.id=== e);
        fetch(`${domain}/questions/${e}`)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            setDetails(data);
            console.log(data);
            
        })
        .catch(err =>{
            alert(err.message);
        })
    }

    const handleDownload = () => {
        fetch(`${domain}/download?type=questions`)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            console.log(data);
            const jsonContent = JSON.stringify(data);
            const blob = new Blob([jsonContent], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'data.json';
            link.click();
        })
        .catch(err =>{
            alert(err.message);
        })
    }

    return(
        <>
        <div className='quickStats position-sticky top-0'>
            {error_stats && <p>{error}</p>}
            {stats_loading && <p>Loading data ....</p>}
            {statistics && 
                <>
                    <div>
                        <i className="fas fa-pray fa-2x"></i>
                        <span className='mt-2'>{statistics.prayer_requests}</span>
                        <p className='fw-bold'>Prayer Requests</p>
                    </div>
                    <div>
                        <i className="fa-solid fa-clipboard-question fa-2x"></i>
                        <span className='mt-2'>{statistics.questions}</span>
                        <p className='fw-bold'>Church Questions</p>
                    </div>
                    <div>
                        <i className="fa-solid fa-users fa-2x"></i>
                        <span className='mt-2'>{statistics.email_subscribers}</span>
                        <p className='fw-bold'>Email Subscriptions</p>
                    </div>
                </>
                }
            </div>
            <div className='questions'>
                <h1 className='fs-4 fw-bold'><span className='fw-bolder text-secondary'>Recent Questions</span> <button className='btn btn-outline-primary btn-sm' onClick={handleDownload}>Download Questions</button></h1>
                {error && <p>{error}</p>}
                {loading && <p>Loading data ....</p>}
                {questions && 
                <table>
                    <thead>
                        <tr>
                            <th style={{width: '5%'}}>#</th>
                            <th style={{width: '20%'}}>Name</th>
                            <th style={{width: '35%'}}>Question</th>
                            <th style={{width: '25%'}}>Date</th>
                            <th style={{width: '10%'}}>Reply <i className="fa-solid fa-reply-all"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        {questions.map(question => {
                            return(
                                <tr key={question.id}>
                                    <td>{question.id}</td>
                                    <td>{question.name}</td>
                                    <td>{question.question}</td>
                                    <td>{question.created_on}</td>
                                    <td onClick={() => handleDetails(question.id)}>Respond</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                }
            </div>
            <div className='overlay' ref={overlay}>
                {details && <DetailsDashboard person = {details} handleHide={handleHide}/>}
            </div>
        </>
    )
}

function Dashboard() {
    const [current, setCurrent] = useState("initial");
    const navigate = useNavigate();

    //for setting the active class
    const handleActivePage = (e) => {
        setCurrent(e.target.getAttribute('name'));
        const links = document.getElementsByClassName("links")[0].getElementsByTagName("a")
        console.log(links);
        for(let i = 0; i<= links.length -1; i++){
            links[i].classList.remove("actives");
        }
        e.target.classList.add("actives");
    }

    const handleLogOut = () => {
        const formData = new FormData();
        let user = JSON.parse(localStorage.getItem("user"));
        let token;
        if(user !== null){
            token = user['token']
        }else{
            return
        }
        formData.append("token",token)
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Token ${token}`,
            },
            credentials: "omit",
            body: formData
        };
        fetch(`${domain}/accounts/logout`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            console.log(data);
            if(data['message'] === 'logout successfull'){
                try {
                    localStorage.removeItem('user');
                    navigate('/')
                } catch (error) {
                    console.log(error);
                }
            }
        })
        .catch((err) => console.log(err))
    }

  return (
    <>
        <div className='Dashboard'>
            <div className='div links'>
                <img src={icon} />
                <figcaption>Pr. Omongole David</figcaption>
                <small>created on 25th/june/2023</small>
                <hr />
                <Link className='actives' onClick={handleActivePage} name="initial">Home</Link>
                <Link name="requests" onClick={handleActivePage}>Prayer Requests</Link>
                <Link name="questions" onClick={handleActivePage}>Questions</Link>
                <Link name="notifications" onClick={handleActivePage}>Set Notifications</Link>
                <Link name="videoStream" onClick={handleActivePage}>Set Video Stream</Link>
                <Link name="subscriptions" onClick={handleActivePage}>Subscribed People</Link>
                <Link name="account" className='d-none' onClick={handleActivePage}>My Account</Link>
                <Link onClick={handleLogOut}>Logout</Link>
            </div>
            <div className='div position-sticky top-0'>
                <div className='system_name'>
                    <p>CRC - Adminstrative Staff Interface </p>
                </div>
                <div className='sticky'>
                    {current === 'initial' && <InitialComponent/>}
                    {current === 'requests' && <PrayerRequests />}
                    {current === 'questions' && <QuestionsDashboard />}
                    {current === 'notifications' && <Notifications />}
                    {current === 'videoStream' && <OnlineChurchStream />}
                    {current === 'subscriptions' && <Subscriptions />}
                    {current === 'account' && <Account />}
                </div>
            </div>
        </div>
    </>
  )
}

export default Dashboard