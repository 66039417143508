import { BsMusicNoteBeamed } from 'react-icons/bs';
import { domain } from "../Domain";
const DisplayTrack = ({ currentTrack, audioRef, setDuration, progressBarRef, handleNext, selectedCategory }) => {
    const onLoadedMetadata = () => {
        const seconds = audioRef.current.duration;
        setDuration(seconds);
        progressBarRef.current.max = seconds;
    };

    return (
        <div>
            <audio hidden src={domain+currentTrack.audio} ref={audioRef} onLoadedMetadata={onLoadedMetadata} onEnded={handleNext} controls />
            <div className="audio-info d-flex align-items-center gap-2">
                <div className="audio-image w-50 bg-light">
                {currentTrack.cover ? (
                    <img src={domain+currentTrack.cover} alt="audio avatar" />
                ) : (
                    <div className="icon-wrapper">
                        <span className="audio-icon">
                            <BsMusicNoteBeamed />
                        </span>
                    </div>
                )}
                </div>
                <div className="w-50">
                    <h3 className='fs-5 fw-bold'>{selectedCategory}</h3>
                    <p className="title m-0 text-success fw-bold">{currentTrack.title}</p>
                    <p className='fw-light'>{currentTrack.description}</p>
                    <p className='m-0 fs-6 text-muted'>{currentTrack.authur}</p>
                </div>
            </div>
        </div>
    );
};
export default DisplayTrack;