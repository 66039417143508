import React from 'react'
import { Link } from 'react-router-dom'
import "./css/mainthree.css"

function MainThree() {
  return (
    <div className="contactUs">
        <div className="row m-auto container">
            <div className="col-lg-4 p-2 pos">
                <div className="bg-danger text-white rounded text-center p-4">
                    <i className="fa-solid fa-headset fs-3 p-3"></i>
                    <h3 className="p-3 fs-4 fw-bold">Call Us Now</h3>
                    <hr/>
                    <address>
                        Christos Rhema Church, 15 Kaminanda Ln, Kampala, <br />
                        0393206175 | 0706 905 656 <br />
                        info@christosrhema.org christosrhemachurch@gmail.com
                    </address>
                </div>
            </div>
            <div className="col-lg-8 p-4 text-white lst position-relative z-1">
                <h5 className="text-danger fs-6 fw-bold">GET IN TOUCH</h5>
                <h1 className="headed text-white">Don't hesitate Contact Us</h1>
                <p className="lead fs-6">Feel free to Contact Us. We are availble 24/7hr and our church is always open. You can visit us at any time of preference or schedule an appointment need be.</p>
                <Link to={'/contact-us'} className="btn btn-danger">Contact Us</Link>
            </div>
        </div>
    </div>
  )
}

export default MainThree