import React, { useState, useEffect } from 'react';
import "./css/annualEvents.css"
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import useFetch from './FetctData';
import { domain } from './Domain';
import DynamicMetaTags from './DynamicMetaTags';
import logo from './images/aim_logo.jpeg'
import { EventsSkeleton } from './WeeklyEvents';
import { truncateString } from './WeeklyEvents';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const emptyArray = [...Array(2)];

/*
style={{background:`url(${props.cover})`, backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"center"}}
*/
function Event(props){

    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = (data) => {
        setShow(true);
        setData(data)
    }

    

    const setSession = (e) => {
        console.log(e.target.getAttribute('data-src'));
        localStorage.setItem("category",e.target.getAttribute('data-src'));
    }

    return(
        <>
            <div className='w-100'>
                <h1 class="lead fw-bold fs-5 text-success">{props.name}</h1>
                <div className='d-block d-md-flex gap-4 align-items-center'>
                    <div className='w-100 w-md-50'>
                        <img src={props.cover} />
                    </div>
                    <div className='w-100 w-md-50'>
                        <div class="fs-6 text-muted pt-2 pb-2">
                            <div className='m-0 p-0' dangerouslySetInnerHTML={{ __html: truncateString(props.details, 100) }} />
                            {props.details.length > 100 && <button className='btn btn-sm m-0 p-0' onClick={()=>handleShow(props)}>continue reading</button>}
                        </div>
                        <div className='hiddenItem'>
                            <Link to={`/annual-events/${props.slug}`} data-src={props.name} onClick={setSession} className='fw-bolder btn btn-success w-100 rounded text-white text-decoration-none'>View Attached Events</Link>
                        </div>
                    </div>
                </div>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title className='fw-bold fs-5'>{data && data.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <img src={props.cover} className='w-100 mb-3'/>
                        <div dangerouslySetInnerHTML={{ __html: props.details }} />
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

function AnnualEvents() {
    const [ spinner, setSpinner ] = useState(true);
    const {data: events, loading, error} = useFetch(`${domain}/events-annual`);

    const pageTitle = "CRC Events | Annual Events"
    const metaDescription = "description"
    const imageUrl = logo
      
    useEffect(() => {
        setTimeout(() => setSpinner(false), 500);
    }, []);
    
  return (
    <>
    {spinner ? <Loader /> : 
        <div>
            <DynamicMetaTags pageTitle = {pageTitle} metaDescription = {metaDescription} imageUrl={imageUrl}/>
            <Navbar />
            <div className='container-lg pt-1 pb-1'>
                <div className="position-relative annualHeader">
                    <h1 className='fs-4 fw-bold pt-4 pb-2 fg'>Our  <span className='fs-3 text-danger'>Annual</span> Events</h1>
                </div>
            </div>
            <div className="events container-lg pt-2 pb-4">
            {error && <p>{error}</p>}
            {loading && emptyArray.map(item => <EventsSkeleton key={item}/>)}
            {events && (
                events.map(data => (
                    <Event 
                        key={data.id} 
                        name={data.name}
                        details={data.details}
                        slug={data.slug}
                        cover={domain+data.cover}
                    />
                ))
            )}
            </div>
            <Footer />
        </div>
    }
    </>
  )
}

export default AnnualEvents