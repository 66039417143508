import React, {useEffect, useState, useRef } from 'react'
import ReactPlayer from 'react-player';
import { domain } from './Domain';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
}
const emptyArray = [...Array(3)];

function Sermon(props){
    const [currentUrl, setCurrentUrl] = useState('');

    const loadVideo = (e) => {
        console.log(e.target.getAttribute('data-src'));
        setCurrentUrl(e.target.getAttribute('data-src'));
        //alert(e.target.getAttribute('data-src'));
        alert("we are here")
    }
    return(
        <div className="col">
            <div className="card">
                <img src={props.cover} className="card-img-top" alt="ministry"/>
                <div className="card-body">
                    <h5 className="card-title fw-bold">{props.name}</h5>
                    <p className="card-text lead fs-6 text-muted">{props.description}</p>
                    <a onClick={loadVideo} data-src = {props.link} data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="text-danger fw-bold text-decoration-none">Watch Sermon</a>
                </div>
            </div>
        </div>
    )
}

const SermonsSkeleton = () => {
    return(
        <SkeletonTheme baseColor="#ddd3" highlightColor="#5555">
            <div className='col'>
                <div className="card">
                    <Skeleton className="card-img-top" height={200} width={"100%"} count={1} />
                    <div className="card-body">
                        <Skeleton className="card-title fw-bold" height={10} width={"70%"} count={1} />
                        <Skeleton className="card-text lead fs-6 text-muted" height={10} width={"100%"} count={3} />
                        <Skeleton height={20} width={"30%"} count={1} />
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    )
}
function MainFour() {
    const [videos, setVideos] = useState([]);
    const [currentUrl, setCurrentUrl] = useState('');
    const [videoName, setVideoName] = useState('');
    const [isPlaying, setPlaying] = useState(false);
    const [loading, setLoading] = useState(true)

    const loadVideo = (e) => {
        console.log(e.target.getAttribute('data-src'));
        setCurrentUrl(e.target.getAttribute('data-src'));
        setVideoName(e.target.getAttribute("name"));
        //alert(e.target.getAttribute('data-src'));
    }

    const stopVid = () => {
        setPlaying(false);
    }
    async function fetchData() {
        const response = await fetch(`${domain}/sermons-random`,{
            mode: "cors", // no-cors, *cors, same-origin,
            //credentials: 'include',
        });
        const jsonData = await response.json();
        //console.log(jsonData);
        setVideos(jsonData);
        setLoading(false);
        //console.log(videos);
    }
      
    useEffect(() => {
        fetchData();
    }, []);
  return (
    <div className="ministries bg-body-tertiary">
        <h5 className="text-danger text-center pt-4">Sermons</h5>
        <h1 className="headed text-center pb-4">Our Sermons</h1>
        <div className="row row-cols-lg-3 row-cols-1 row-cols-md-2 g-4 m-auto container" style={{paddingBottom: "80px"}}>
            {
                loading ? emptyArray.map(item => (
                    <SermonsSkeleton key={item}/>
                ))
                :
                videos.length > 0 && (
                    videos.map(data => (
                        <div key={data.id} className="col">
                            <div className="card">
                                <img src={domain+data.cover} className="card-img-top" alt="ministry"/>
                                <div className="card-body">
                                    <h5 className="card-title fw-bold">{data.name}</h5>
                                    <p className="card-text lead fs-6 text-muted">{truncateString(data.description, 100)}</p>
                                    <a onClick={loadVideo} data-src = {data.link} name={data.name} data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="text-danger fw-bold text-decoration-none">Watch Sermon</a>
                                </div>
                            </div>
                        </div>
                    ))
                )
            }
        </div>
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">{videoName}</h1>
                        <button type="button" className="btn-close" onClick={stopVid} data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body m-0 p-0">
                    <ReactPlayer
                        className='react-player'
                        id="react-player"
                        //light={<img src='images/network.png' width="100%" alt='Thumbnail' />}
                        url={currentUrl}
                        playing={isPlaying}
                        width='100%'
                        height='315px'
                        controls={true}
                        onPlay={() => setPlaying(true)}
                        onPause={() => setPlaying(false)}
                    />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MainFour
