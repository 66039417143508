const ProgressBar = ({ progressBarRef, audioRef, timeProgress, duration }) => {
    const handleProgressChange = () => {
        audioRef.current.currentTime = progressBarRef.current.value;
    };
    const formatTime = (time) => {
        if (time && !isNaN(time)) {
          const hours = Math.floor(time / 3600);
          const formatHours = hours < 10 ? `0${hours}` : `${hours}`;
          const minutes = Math.floor((time % 3600) / 60);
          const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
          const seconds = Math.floor(time % 60);
          const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      
          if (hours > 0) {
            return `${formatHours}:${formatMinutes}:${formatSeconds}`;
          } else {
            return `${formatMinutes}:${formatSeconds}`;
          }
        }
        return '00:00';
    };
    
    return (
            <div className="progress d-flex align-items-center gap-2">
                <span className="time current">{formatTime(timeProgress)}</span>
                <input
                    type="range"
                    ref={progressBarRef}
                    defaultValue="0"
                    onChange={handleProgressChange}
                />
                <span className="time">{formatTime(duration)}</span>
            </div>
        );
    };
  
export default ProgressBar;