import React, {useState, useRef} from 'react'
import { domain } from './Domain';


function DetailsDashboard({person, handleHide}) {
    const [response, setResponse] = useState("");
    const [isPending, setIsPending] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const textRef = useRef()

    const handleSendResponse = (e) => {
        setIsPending(true);
        let formData = new FormData();
        formData.append("id", e);
        formData.append("answer",response);
        const requestOptions = {
            method: 'POST',
            credentials: "omit",
            body: formData
        };
        fetch(`${domain}/answers`, requestOptions)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            textRef.current.value="";
            setData(data);
            setIsPending(false);
            setError(null);
            
        })
        .catch(err =>{
            setError(err.message);
        })
        
    }   

    function validateForm() {
        return response.length > 0;
    }

    return(
        <div key={person.id} className='inner'>
            <div className='d-flex justify-content-end'>
                <p style={{cursor:"pointer"}} onClick={() => handleHide()}><i className="fa-regular fa-circle-xmark fa-2x"></i></p>
            </div>
            <small>From: </small>
            <h2 className='fw-bolder'>{person.name}</h2>
            <h4 className='pt-3' style={{fontFamily:"Playfair"}}>Question to answer</h4>
            <p className='fw-light' dangerouslySetInnerHTML={{ __html: person.qtn }}></p>
            <hr />
            <textarea ref={textRef} className='form-control' rows={5} onChange={(e) => setResponse(e.target.value)} placeholder='Enter a response to the above question'></textarea>
            {!isPending && <button className='btn btn-danger mt-3' disabled={!validateForm()} onClick={() => handleSendResponse(person.id)}>Send Response</button>}
            {isPending && <button className='btn btn-danger mt-3' disabled>Sending Response ...</button>}
        </div>
    )
}

export default DetailsDashboard