import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom'
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import { domain } from './Domain';
import useFetch from './FetctData';
import SimplePeer from 'simple-peer';
import io from 'socket.io-client';

function OnlineChurchRecieverStream() {
    const [ spinner, setSpinner ] = useState(true);
    const videoRef = useRef(null);
    let peer, socket;

    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000);
        // Connect to the signaling server
        socket = io('http://localhost:80', {
            // Provide your custom headers here
            cors: true,
        });

        socket.on('connect', () => {
            console.log('Connected to signaling server now');
            // Set up signaling data exchange with the sender
            socket.on('receiver-signal', (data) => {
                console.log("data has been recieved")
                
                if (!peer) {
                    console.log("there was no peer hence creating the peer")
                    // Create the WebRTC peer connection
                    peer = new SimplePeer({ initiator: false, stream: null });
        
                    peer.on('signal', (signalData) => {
                    // Send the signaling data back to the sender through the signaling server
                    socket.emit('sender-signal', signalData);
                    });
        
                    peer.on('stream', (remoteStream) => {
                    // This event is triggered when the remote stream is received.
                    // Update the video element with the received stream.
                    videoRef.current.srcObject = remoteStream;
                    });
        
                    // Signal the sender with the received signaling data
                    peer.signal(data);
                }
            });
        });
      
    
        // Cleanup on unmount
        return () => {
            if (peer) {
                peer.destroy();
            }
            if (socket) {
                socket.disconnect();
            }
        };
        
    }, []);

    return (
        <>
            {spinner ? <Loader /> : 
                <div>
                    <Navbar />
                    <div className='row m-auto'>
                        <div className='col-7 pt-2 ps-4'>
                            <h1 className='fs-5 pt-3 text-success fw-bold'>Sermon Title <small className='text-danger d-none'>Date - 27th/June/2022</small></h1>
                            <video
                                ref={videoRef}
                                className='rounded'
                                autoPlay // Start playing the video automatically.
                                style={{width: "100%", height: "400px", objectFit: "cover", background: 'rgba(3, 73, 134, 0.5)'}}
                            />
                            <h3 className='fs-5 lead fw-bold fg'>Sermon Description</h3>
                            <p className='lead fs-6 pt-2'></p>
                        </div>
                        <div className='col-5'>
                            <h1 className='fs-4 pt-4 fw-bolder' style={{color:"tomato"}}>Live Chat </h1>
                            <p className='d-none'></p>
                            <textarea rows={3} className='w-100 p-2 border rounded'  placeholder='please type your comment here ...'></textarea>
                            <button className='btn btn-sm btn-success' disabled>send message ...</button>
                        </div>
                    </div>
                    <Footer />
                </div>
            }
        </>
    )
}

export default OnlineChurchRecieverStream