import { useEffect, useState } from "react";

const usePost = (url, formData) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(()=>{
        fetch(url,{
                method: "POST",
                mode: "cors", // no-cors, *cors, same-origin,
                //credentials: 'include',
                body: formData,
        })
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            setData(data);
            setLoading(false);
            setError(null);
        })
        .catch(err =>{
            setError(err.message);
            setLoading(false);
        })

    },[url, formData])
    return {data, loading, error}
}

export default usePost