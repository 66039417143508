import React from 'react'
import { Link } from 'react-router-dom'
import "./css/navbar.css"
export default function Navbar() {
    
  return (
    <>
        <div className="row m-auto top">
            <div className="col-8 d-flex justify-content-start align-items-center">
                <a href="#" className='d-none'><i id = "fa-location-dot" className="fa-solid fa-location-dot text-danger"></i></a>
                <span className='fw-bolder'>Christos Rhema Church - CRC</span>
            </div>
            <div className="col-4 socials d-flex justify-content-end align-items-center">
                <a href="https://wa.me/256706905656?text=greetings"><i className="fa-brands fa-whatsapp text-info"></i></a>
                <a href="https://www.facebook.com/christosrhemachurch"><i className="fa-brands fa-facebook text-primary"></i></a>
                <a href="https://twitter.com/RhemaChristos"><i className="fa-brands fa-twitter text-info"></i></a>
                <a href="https://instagram.com/christos_rhema_church?igshid=NTA5ZTk1NTc="><i className="fa-brands fa-instagram text-success"></i></a>
                <a href="https://www.youtube.com/@PsOmongoleDavidcrc"><i className="fa-brands fa-youtube text-danger"></i></a>
                <a href="#"><i className="fa-brands fa-telegram text-primary"></i></a>
            </div>
        </div>
        <nav className="navbar navbar-expand-lg bg navbar-dark">
            <div className="container-fluid container-lg">
                <a className="navbar-brand fw-bold fs-6" href="#"> Agapao International<br /> Ministries </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link to="/" className="nav-link active" aria-current="page"><li>Home</li></Link>
                        </li>
                        <li className="nav-item  dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Sermons
                            </a>
                            <ul className="dropdown-menu">
                                <li><Link to="/sermons-audio" className="dropdown-item" >Audio Sermons</Link></li>
                                <li><Link to="/sermons-video" className="dropdown-item" >Video Sermons</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Ministries
                            </a>
                            <ul className="dropdown-menu">
                                <li><Link to="/woman-of-substance" className="dropdown-item" >Woman of Substance</Link></li>
                                <li><a className="dropdown-item d-none" href="#">Children's Ministries</a></li>
                                <li><a className="dropdown-item d-none" href="#">Ushering</a></li>
                                <li><a className="dropdown-item d-none" href="#">Media</a></li>
                                <li><a className="dropdown-item d-none" href="#">Choir</a></li>
                                <li><Link to="/love-hands" className="dropdown-item" >Love hands</Link></li>
                                <li><Link to="/rhema-collge" className="dropdown-item" >Rhema Collge</Link></li>
                                <li><a className="dropdown-item d-none" href="#">In-reach</a></li>
                                <li><a className="dropdown-item d-none" href="#">Out-reach</a></li>
                                <li><a className="dropdown-item d-none" href="#">Champions</a></li>
                                <li><a className="dropdown-item d-none" href="#">Cells & fellowships</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Events
                            </a>
                            <ul className="dropdown-menu">
                                <li><Link to="/annual-events" className="dropdown-item" >Annual Events</Link></li>
                                <li><Link to="/weekly-events" className="dropdown-item" >Weekly Events</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link to="/questions" className="nav-link" aria-current="page"><li>Ask Pr. David</li></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/branch-churches" className="nav-link" aria-current="page"><li>Branch Churches</li></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/contact-us" className="nav-link" aria-current="page"><li>Contact Us</li></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/staff" className="nav-link" aria-current="page"><li>About Us</li></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/give" className="nav-link" aria-current="page"><li>Give</li></Link>
                        </li>
                    </ul>
                    <form className="d-flex d-none" role="search">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
                        <button className="btn btn-outline-success" type="submit">Search</button>
                    </form>
                </div>
            </div>
        </nav>
    </>
  )
}
