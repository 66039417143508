import React, { useState, useEffect } from 'react';
import "./css/eventDetails.css"
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link, useParams } from 'react-router-dom';
import { domain } from './Domain';
import DynamicMetaTags from './DynamicMetaTags';
import logo from './images/aim_logo.jpeg'

function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
}


function EventDetails() {
    const [ spinner, setSpinner ] = useState(true);
    const [event, setEvent] = useState([]);
    const [isReloaded, setReloaded] = useState(false);
    const { name } = useParams();
    
    const pageTitle = `CRC Events | ${name}`
    const [metaDescription, setMetaDesciption] = useState()
    const imageUrl = logo

    async function fetchData(slug) {
        const response = await fetch(`${domain}/events-random-details/${slug}`,{
            mode: "cors", // no-cors, *cors, same-origin,
            //credentials: 'include',
        });
        const jsonData = await response.json();
        //console.log(jsonData);
        setEvent(jsonData);
        setMetaDesciption(truncateString(jsonData['details'], 60))
        //console.log(event.cover);
    }

    const reloadComponent =()=> {
        setReloaded(true);
    }

    
    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000);
        /*get the last segment of the route
        var parts = window.location.href.split('/');
        var lastSegment = parts.pop() || parts.pop();  // handle potential trailing slash
        
        */fetchData(name);
        setReloaded(false);

    }, [isReloaded]);

    return (
        <>
            {spinner ? <Loader /> : 
                <div>
                    <DynamicMetaTags pageTitle = {pageTitle} metaDescription = {metaDescription} imageUrl={imageUrl}/>
                    <Navbar />
                    <div className='container-lg row m-auto'>
                        <div className='col-lg-7'>
                            {event && (
                                <div className='event'>
                                    <h1 className="fg fs-6 pt-4 pb-1 fw-bold">{event.category} Event</h1>
                                    <h1 className="text-danger fs-4 pb-2 fw-bold">{event.main_name} - <span className='fg fw-bold'>{event.name}</span></h1>
                                    <small className='text-muted'>Event held on {event.created_at}</small>
                                    <img src={domain+event.cover} alt='cover' className='rounded' />
                                    <p className='lead pt-3 fs-6' dangerouslySetInnerHTML={{ __html: event.details }}></p>
                                </div>
                            )}
                            <h2 className="fg fs-5 pb-1 fw-bold">Event Gallery</h2>
                            <div className='eventGallery'>
                                {event.images.length > 0 && (
                                    event.images.map(data => (
                                        <img key={data.id} src={domain+data.image} alt='gallery' />
                                    ))
                                )}
                            </div>
                        </div>
                        <div className='col-lg-4'>
                        <h1 className="fg fs-6 pt-4 pb-1 fw-bold">Others</h1>
                            <h1 className="text-success fs-4 pb-2 fw-bold">Our Latest {event.category} events</h1>
                            {event.others.length > 0 && (
                                event.others.map(data => (
                                    <div className='flex_other_details' key={data.id}>
                                        <img src={domain+data.cover} className='me-1' alt='myImg' />
                                        <div>
                                            <h2>{truncateString(data.name, 30)}</h2>
                                            <Link to={"/details/"+data.slug} onClick={reloadComponent} className="d-flex fw-bold text-decoration-none">
                                            <p className='lead' dangerouslySetInnerHTML={{ __html: truncateString(data.details, 80) }}></p>
                                            </Link>
                                        </div>
                                    </div>
                                    
                                ))
                            )}
                        </div>
                    </div>
                    <Footer />
                </div>
            }
        </>
  )
}

export default EventDetails