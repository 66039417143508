import React from 'react'
import "./css/mainone.css"
import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom'
import { domain } from './Domain';
import usePost from './PostData';
import useFetch from './FetctData';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
}

function SundayService({service}){
    return(
        <div className='container-lg row m-auto pt-4 pb-4 mb-4'>
            <div className='col-lg-5'>
                <img src={domain+service.cover} className='w-100 rounded-top'/>
            </div>
            <div className='col-lg-7'>
                <h1 className='fs-3 fw-bolder text-success'>{service.name}</h1>
                <small className='text-muted'>Posted on {new Date(service.created_at).toDateString()}</small>
                <p className='fw-light mt-3' dangerouslySetInnerHTML={{ __html: truncateString(service.details, 500) }}></p>
                <Link className='d-block mb-2 text-decoration-none link'>View details →</Link>
            </div>
        </div>
    )
}

function DailyCornet({cornet}){
    return(
        <div>
            <h1 className='text-center fs-6 position-relative text-danger'>Cornet Bible Verse</h1>
            <p className="fs-5 w-75 m-auto pt-2 pb-2"><i class="fa-solid fa-quote-left text-secondary me-2"></i>{cornet.verses.message}<i class="fa-solid fa-quote-right text-secondary ms-2"></i></p>
            <p className='d-flex align-items-center justify-content-center text-secondary fs-5'> - {cornet.verses.verse}</p>
        </div>
    )
}
//to={`/service/${midweek.slug}`}
function MainOne() {
    const requestRef = useRef();
    const [isPending, setIsPending] = useState(false);
    const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/cornet`);
    const {data:midweek, loading:loadingMidweek} = useFetch(`${domain}/service?q=midweek-service&many=false`);
    const {data:sundayService, loading:loadingSunday} = useFetch(`${domain}/service?q=sunday-service&many=false`);

    const sendRequest = () => {
        let form = document.getElementById("prayer_request_form");
        form.onsubmit = function (event) {
            setIsPending(true);
            requestRef.current.textContent ="sending ...";
            event.preventDefault();
            let data = new FormData(form);
            const requestOptions = {
                method: 'POST',
                cors: "cors",
                body: data
            };
            fetch(`${domain}/prayer-requests`, requestOptions)
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                form.reset();
                requestRef.current.textContent = "Dear " + data['name'] + " , your prayer request has been received";
                setTimeout(function(){
                    requestRef.current.textContent = "";
                },5000);
                setIsPending(false);
            })
            .catch((err) => console.log(err))
        }
    }

    const dailyDevotional = (e) => {
        console.log(e.target.getAttribute('data-src'));
        document.getElementById("object").setAttribute("data",e.target.getAttribute('data-src'));
    }

    const MidweekSkeleton = () => {
        return(
            <div>
                <SkeletonTheme baseColor="#ddd3" highlightColor="#5555">
                    <div>
                        <Skeleton height={20} width={"30%"}  count={1} />
                        <Skeleton height={30} count={1} width={"40%"} />
                        <Skeleton height={250} width={"100%"} count={1} />
                        <Skeleton height={10} width={"10%"} count={1} />
                        <Skeleton height={30} count={1} width={"40%"} className='rounded-pill' />
                    </div>
                </SkeletonTheme>
            </div>
        )
    }
    const CornetSkeleton = () => {
        return(
            <div>
                <SkeletonTheme baseColor="#ddd3" highlightColor="#5555">
                    <div>
                        <Skeleton height={20} width={"30%"}  count={1} />
                        <Skeleton height={10} count={1} width={"90%"} />
                        <Skeleton height={10} width={"50%"} count={1} />
                        <Skeleton height={10} width={"30%"} count={1} />
                    </div>
                </SkeletonTheme>
            </div>
        )
    }

    const SundaySkeleton = () => {
        return(
            <SkeletonTheme baseColor="#ddd3" highlightColor="#5555" className='container-lg row m-auto pt-4 pb-4 mb-4'>
                <div className='col-lg-5'>
                    <Skeleton height={200} width={"100%"}  count={1} className='w-100 rounded-top'/>
                </div>
                <div className='col-lg-7'>
                    <Skeleton height={30} width={"30%"}  count={1} />
                    <Skeleton height={10} width={"15%"} count={1} className='text-muted' />
                    <Skeleton height={10} width={"100%"} count={4} />
                    <Skeleton height={10} width={"30%"} count={1} />
                    <Skeleton height={20} width={"30%"} count={1} />
                </div>
            </SkeletonTheme>
        )
    }

    return (
        <>
            <div className="row m-auto g-2 pt-4 pb-4 container">
                <div className="col-lg-6 d-md-block">
                    <div className='midweek'>
                        {loadingMidweek ? 
                            <MidweekSkeleton /> 
                            :
                            midweek &&  
                                <div>
                                    <h5 className="fw-bold text-success">Midweek Pervious Sermon</h5>
                                    <h1 className='headed fs-4 fg'>{new Date(midweek.created_at).toDateString()} Church Service</h1>
                                    <img src={domain+midweek.cover} className='d-block'/>
                                    <figcaption className='fw-bold pt-2 text-uppercase'>{midweek.name}</figcaption>
                                    <p style={{color: '#6f6a66'}} dangerouslySetInnerHTML={{ __html: truncateString(midweek.details,100) }}></p>
                                    <Link className='d-block mb-2 text-decoration-none link' >View details →</Link>
                                    <Link className='btn btn-outline-danger text-decoration-none rounded-pill mt-2' >View All Previous Sermons</Link>
                                </div>
                            }
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="p-2">
                        <h5 className="fw-bold text-danger">Cornet Daily devotional</h5>
                        <h1 className='headed'>Cornet Daily devotional</h1>
                        <p className="p_styled">The Cornet Daily Devotional is packaged with the word of God to guide you in your walk with God and to enhance your Spiritual growth.</p>
                        <Link className='btn btn-outline-danger mb-4' to={data && domain+data[0].file}>Read | Download Cornet Daily Devotional</Link>
                        <button className='btn d-none btn-outline-danger mb-4' data-bs-toggle="modal" data-bs-target="#dailyDevotional" onClick={dailyDevotional} data-src={data && domain+data[0].file}>Read | Download Cornet Daily Devotional</button>
                        <div>
                            <div>
                                <i className="d-none fa-solid fa-heart bg-danger text-white rounded-circle p-3"></i>
                                <div className='d-flex align-items-center'>
                                    <img src='/images/pr.david.jpeg' style={{width: "80px", height: "80px", objectFit: "cover"}} className='img-thumbnail rounded-circle' />
                                    <h2 className="fs-5 ps-2 text-success fw-bold pt-2 pb-2">Pray with Pr. David Omongole</h2>
                                </div>
                                <p className="lead pt-2 fs-6 text-muted">Do you have a <span className='text-danger fw-bold'>prayer request?</span></p>
                                <Link className='btn btn-danger' data-bs-toggle="modal" data-bs-target="#staticBackdrops">Submit Prayer Request</Link>
                            </div>
                        </div>
                        <button className="d-none btn btn-danger">Learn More</button>
                        <div className="modal fade" id="staticBackdrops" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header bg-success">
                                        <h1 className="modal-title fs-5 text-white fw-bold" id="staticBackdropLabel">Thank you for opting to share your prayer request with Pr. David Omongole.</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body m-0 p-4">
                                        <form id='prayer_request_form'>
                                            <p ref={requestRef}></p>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-addon1">name</span>
                                                <input type="text" name='name' required className="form-control" placeholder="Enter your name" aria-label="Username" aria-describedby="basic-addon1"/>
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-addon1">phone</span>
                                                <input type="text" name='phone' className="form-control" placeholder="Phone number e.g. 0787000000" aria-label="Usrname" aria-describedby="basic-addon1"/>
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-addon1">address</span>
                                                <input type="text" name='address' className="form-control" placeholder="Address e.g. Kikoni" aria-label="Username" aria-describedby="basic-addon1"/>
                                            </div>
                                            <textarea className="form-control mb-3" name='request' required aria-label="With textarea" style={{height: "120px"}} placeholder='Enter your prayer request here'></textarea>
                                            {! isPending && <button className='btn btn-danger mt-3' onClick={sendRequest}>Submit Prayer Request</button>}
                                            {isPending && <button className='btn btn-danger mt-3' disabled>Sending Prayer Response ...</button>}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal modal-lg fade" id="dailyDevotional" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Daily Devotional</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body bg-success m-0 p-0">
                                        <object id='object' data="" width="100%" height="400"></object>
                                        <iframe src='' id='object2' width="100%" height="400" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='cornetMessage'>
                {loading ? 
                    <CornetSkeleton />
                    :
                    data && <DailyCornet cornet={data[0]}/>
                }
            </div>
            <div>
                <h5 className="text-danger text-center pt-4">Services</h5>
                <h1 className='headed text-center'>Previous Sunday Service</h1>
                {loadingSunday ? <SundaySkeleton />
                :
                sundayService && <SundayService service={sundayService}/>
                }
            </div>
        </>
    )
}

export default MainOne