import React, {useEffect, useState } from 'react';
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import DynamicMetaTags from './DynamicMetaTags';
import logo from './images/aim_logo.jpeg'

function WomanOfSubstance() {
    const [ spinner, setSpinner ] = useState(true);
    const pageTitle = "CRC Ministries | Woman of Substance"
    const metaDescription = "description"
    const imageUrl = logo

    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000);
    }, []);

    return (
        <>
        {spinner ? <Loader /> : 
            <div>
                <DynamicMetaTags pageTitle = {pageTitle} metaDescription = {metaDescription} imageUrl={imageUrl}/>
                <Navbar />
                <div className='container-lg'>
                <h1 className='fs-4 fw-bold pt-4 pb-2 fg'>The Women & Childrens  <span className='fs-3 text-danger'>Directorate</span></h1>
                    <p className='lead fs-6'>This directorate encompasses all the women and children who are in all churches, Ruach fellowships, and other ministries under Agapao International Ministries. The women’s ministry runs with the name <q className='fw-bold text-success'>Woman of Substance and the children’s ministry runs with the name The Word Begotten.</q> </p>
                    <p className='lead fs-6'>The purpose of the women’s arm is to make the women Christ conscious not more gender conscious and that of the children’s arm is to raise Word equipped children who are trained up in the way they should go.  </p>
                    <h3 className='fg fs-5 pt-4 pb-2 fw-bold'>Woman of Substance Agenda</h3>
                    <ul>
                        <li className='lead fs-6'>Developing ladies who are fully grown in the Word of God </li>
                        <li className='lead fs-6'>Amplifying the spiritual gifts in each woman, preparing them for the work of the ministry and showing them how to influence their environment for the better. </li>
                        <li className='lead fs-6'>Standing with each other during both joyous and trying moments</li>
                        <li className='lead fs-6'>Reaching out to all those who are in need and being a solution giver as well as a problem solver.</li>
                    </ul>
                    <h3 className='fg fs-5 pt-4 pb-2 fw-bold'>The Word Begotten Agenda</h3>
                    <ul>
                        <li className='lead fs-6'>Equipping children with the Word of God so that they can apply it in their everyday lives </li>
                        <li className='lead fs-6'>Incorporating children in the work of the ministry from a young age </li>
                        <li className='lead fs-6'>Developing the talents and gifts of each individual child.  </li>
                        <li className='lead fs-6'>Inspiring children who may have lost hope in the world </li>
                    </ul>
                </div>
                <Footer />
            </div>
        }
        </>
    )
}

export default WomanOfSubstance