import React, {useState, useRef} from 'react'
import useFetch from './FetctData';
import DetailsDashboard from './DetailsDashboard';
import { domain } from './Domain';

function QuestionsDashboard() {
    const [url, setUrl] = useState(`${domain}/questions`)
    const {data: questions, loading, error, refresh, setRefresh} = useFetch(url);
    const [details, setDetails] = useState(null);
    const overlay = useRef();

    const handleRequests = (e) => {
        e.preventDefault();
        //sample of the logic to performa a filter
        let search = e.target.question.value;    
        setUrl(`${domain}/questions?search=${search}`);
    }

    const handleFiltering =(e)=>{
        console.log(e.target.value.length);
        if(e.target.value.length === 0){
            setUrl(`${domain}/questions`);
        }

    }

    const handleDate = (e) => {
        e.preventDefault();
        let start_date = e.target.start_date.value;  
        let end_date = e.target.end_date.value; 
        if(start_date !== '' && end_date !== ""){
            setUrl(`${domain}/questions?start_date=${start_date}&end_date=${end_date}`);   
        }else{
            alert("please provide a date range");
        }
    }

    const handleDetails = (e) => {
        overlay.current.style.display = "block"
        //const filtered = questions.filter((question) => question.id=== e);
        //setDetails(filtered);
        fetch(`${domain}/questions/${e}`)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            setDetails(data);
            console.log(data);
        })
        .catch(err =>{
            alert(err.message);
        })
    }

    const handleHide = (e) => {
        setDetails(null);
        overlay.current.style.display = "none";
    }

    const handleDownload = () => {
        fetch(`${domain}/download?type=questions`)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            console.log(data);
            const jsonContent = JSON.stringify(data);
            const blob = new Blob([jsonContent], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'data.json';
            link.click();
        })
        .catch(err =>{
            alert(err.message);
        })
    }

  return (
    <div>
        <div className='filter'>
            <form onSubmit={handleRequests}>
                <input type='text' onChange={handleFiltering} name ="question" placeholder='search for question'/>
                <button>search questions</button>
            </form>
            <form onSubmit={handleDate}>
                <div>
                    <label>start date</label>
                    <input type='date' name ="start_date"/>
                </div>
                <div>
                    <label>end date</label>
                    <input type='date' name ="end_date"/>
                </div>
                <div>
                    <label>filter questions</label>
                    <button>get questions</button>
                </div>
            </form>
        </div>
        <div className='requests'>
            <h1 className='fs-4 fw-bold'><span className='fw-bolder' style={{color:"tomato"}}>Availale Congregation Questions</span> <button className='btn btn-outline-primary' onClick={handleDownload}>Download Questions</button><button className='btn btn-outline-danger ms-2'>View All Questions</button></h1>
            {error && <p>{error}</p>}
            {loading && <p>Loading data ....</p>}
            {questions && 
            <table>
                <thead>
                    <tr>
                        <th style={{width: '5%'}}>#</th>
                        <th style={{width: '20%'}}>Name</th>
                        <th style={{width: '35%'}}>Question</th>
                        <th style={{width: '25%'}}>Date</th>
                        <th style={{width: '10%'}}>Reply <i className="fa-solid fa-reply-all"></i></th>
                    </tr>
                </thead>

                <tbody>
                    {questions.map(question => {
                        return(
                            <tr key={question.id}>
                                <td>{question.id}</td>
                                <td>{question.name}</td>
                                <td>{question.question}</td>
                                <td>{question.created_on}</td>
                                <td onClick={() => handleDetails(question.id)}>Respond</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            }
        </div>
        <div className='overlay' ref={overlay}>
            {details && <DetailsDashboard person = {details} handleHide={handleHide} refresh={refresh} setRefresh={setRefresh} />}
        </div>
    </div>
  )
}

export default QuestionsDashboard