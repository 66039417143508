import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import "./css/school.css"
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import { domain } from './Domain';

function RegistrationForm() {
    const [ spinner, setSpinner ] = useState(true);
    const [ response, setResponse ] = useState(true);
    const [isPending, setIsPending] = useState(false);

    async function postData(data) {
        setIsPending(true);
        const response = await fetch(`${domain}/rhema-college-applications`,{
            mode: "cors", // no-cors, *cors, same-origin,
            method: "POST",
            body: data
            //credentials: 'include',
        });
        const jsonData = await response.json();
        //console.log(jsonData);
        setResponse(jsonData);
        setIsPending(false);
        document.getElementById("responses").innerHTML = `Holla, ${jsonData['fname']} ${jsonData['lname']}, Your Application has been Received!`;
        setTimeout(function(){
            document.getElementById("responses").innerHTML = "";
        }, 3000)
    }

    function handleRegisterForm(){
        let form = document.getElementById("registerForm");
        form.onsubmit = function(event){
            event.preventDefault()
            let form_data = new FormData(form);
            document.getElementById("responses").textContent = "sending ...";
            postData(form_data);
            form.reset(); 
        }
    }

    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000);
        
    }, []);
    return (
        <>
        {spinner ? <Loader /> : 
            <div className='bgs'>
                <Navbar />
                <div className='container-lg'>
                    <h1 className='fs-4 pt-4 lead fw-bold text-danger'>Rhema College</h1>
                    <h2 className='fs-6 pt-2 lead fw-bold text-secondary'>Register to Enroll Now</h2>
                    <div className='row'>
                        <form id='registerForm' className='col-lg-5'>
                            <p className="text-info fs-6 fw-bold" id='responses'></p>
                            <label>Fullname</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="addon-wrapping"><i className="fa-solid fa-file-signature"></i></span>
                                <input type="text" name='fname' aria-label="First name" placeholder='Enter your firstname' required className="form-control"/>
                                <input type="text" name='lname' aria-label="Last name" placeholder='Enter your lastname' required className="form-control"/>
                            </div>
                            <label>Email Address</label>
                            <div className="input-group flex-nowrap mb-3">
                                <span className="input-group-text" id="addon-wrapping"><i className="fa-solid fa-envelope"></i></span>
                                <input type="email" name='email' className="form-control" placeholder="Enter your Email Address" aria-label="Username" aria-describedby="addon-wrapping"/>
                            </div>
                            <label>Phone number</label>
                            <div className="input-group flex-nowrap mb-3">
                                <span className="input-group-text" id="addon-wrapping"><i className="fa-solid fa-mobile-screen"></i></span>
                                <input type="text" name='phone' className="form-control" placeholder="Enter your phone number" required aria-label="Username" aria-describedby="addon-wrapping"/>
                            </div>
                            <label>Current Ministry</label>
                            <div className="input-group flex-nowrap mb-3">
                                <span className="input-group-text" id="addon-wrapping"><i className="fa-solid fa-church"></i></span>
                                <input name='ministry' required type="text" className="form-control" list='ministry_options' placeholder="What ministry are you in ?" aria-label="Username" aria-describedby="addon-wrapping"/>
                            </div>
                            <label>Occupation</label>
                            <datalist id='ministry_options'>
                                <option value={'choir'}></option>
                                <option value={'inreach'}></option>
                                <option value={'outreach'}></option>
                            </datalist>
                            
                            <div className="input-group flex-nowrap mb-3">
                                <span className="input-group-text" id="addon-wrapping"><i className="fa-solid fa-user-doctor"></i></span>
                                <input type="text" required name='occupation' className="form-control" placeholder="What is your occupation?" aria-label="Username" aria-describedby="addon-wrapping"/>
                            </div>
                            <label>Do you have any questions ?</label>
                            <textarea className="form-control mb-3" name='question' rows={5} placeholder='Do you have any questions about the college'></textarea>
                            {!isPending && <button className='btn btn-success mb-3' onClick={handleRegisterForm}>Submit Registeration Details</button>}
                            {isPending && <button className='btn btn-success mb-3' disabled onClick={handleRegisterForm}>Submiting Registeration Details ...</button>}
                        </form>
                        <div className='col-lg-7'>
                            <h1 className='fs-5 fw-bold text-danger'>History of Rhema College</h1>
                            <p className='m-auto lead fs-6 pt-2'>It is important to note that the college originally <b style={{fontWeight:700}}>(Rhema bible class)</b> started sometime back when Pr. Omongole David was still a student at <b className='text-success' style={{fontWeight:700}}>Mbarara University.</b> However it had its first graduation Ceremony as a Directorate under AGAPAO International Ministries in 2017 under the leadership of Minister Derrick Sserunkuuma the class captain by then, but now the appointed Director.</p>
                            <h2 className='fs-5 mt-3 fg fw-bold lead '>Contact Information</h2>
                            <address className='text-muted'>
                                <p><i className="fa-solid fa-mobile-screen-button"></i> +256-788-116345 or +1 567-03727462.</p>
                                <p><i className="fa-solid fa-envelope"></i> christosrhemachurch@gmail.com</p>
                            </address>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        }
        </>
  )
}

export default RegistrationForm