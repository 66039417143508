import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { domain } from './Domain'
import Carousel from 'react-bootstrap/Carousel';
import "./css/carousel.css"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

/*
<!--className='d-none d-md-block'-->
*/
export const Data = (props) =>{
    return(
        <div key={props.id} className="carousel-item active" data-bs-interval="10000">
            <img src={domain+props.image} alt="tagged" />
            <h4 className='p_styled d-none'>{props.welcome}</h4>
            <div className="carousel-caption title d-md-block d-none">
                <h5 className="fw-bold fs-1 lead">{props.title}</h5>
                <p className="lead" dangerouslySetInnerHTML={{ __html: props.description }}></p>
                <div>
                    <Link to="/give"  className="btn btn-lg fs-6 btn-outline-warning rounded-0">Give To The House of the Lord</Link>
                </div>
            </div>
        </div>
    )
}
export const Datas = (props) => {
    return (
        <div key={props.id} className="carousel-item" data-bs-interval="10000">
            <img src={domain+props.image} alt="tagged" />
            <h4 className='p_styled d-none'>{props.welcome}</h4>
            <div className="carousel-caption d-md-block d-none">
                <h5 className="fw-bold fs-1 lead">{props.title}</h5>
                <p className="lead" dangerouslySetInnerHTML={{ __html: props.description }}></p>
                <div>
                    <Link to="/give" className="btn btn-lg fs-6 btn-outline-warning rounded-0">Give To The House of the Lord</Link>
                </div>
            </div>
        </div>
    )
}

export const CarouselSlidder = () => {
    const [carousel, setUsers] = useState([])
    const [spinner, setSpinner] = useState(true); 

    const fetchData = () => {
        fetch(`${domain}/carousel`,{
            mode: 'cors',
            //credentials: "include", // include, *same-origin, omit
        })
        .then(response => response.json())
        .then(result => {
            console.log('Success:', result);
            setUsers(result)
            setSpinner(false);

        })
        .catch(error => {
            console.error('Error:', error);
        });
    }
    useEffect(() => {
        fetchData()
    }, [])
  return (
    <Carousel indicators={true} fade>
        {spinner ? 
            <SkeletonTheme baseColor="#ddd3" highlightColor="#5555">
                <div>
                    <Skeleton height={350} width={"100%"} count={1} />
                </div>
            </SkeletonTheme>
        :
        carousel.length > 0 && (
            carousel.map((carusel, index) => (
                <Carousel.Item key={index}>
                    <img src={domain + carusel.image} className='d-block w-100' alt="tagged" />
                    <Carousel.Caption className='carousel-caption'>
                        <h3 className='p_styled text-white'>{carusel.title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: carusel.description }} ></p>
                    </Carousel.Caption>
                </Carousel.Item>
            )) 
        )}
    </Carousel>
  )
}
