import React, { useState, useEffect } from 'react';
import "./css/give.css"
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { domain } from './Domain';

function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
}


function Give() {
    const [ spinner, setSpinner ] = useState(true);
    const [partnerships, setPartnerships] = useState([])
    // It will be executed before rendering

    async function fetchData() {
        const response = await fetch(`${domain}/partnerships`,{
            mode: "cors", // no-cors, *cors, same-origin,
            //credentials: 'include',
        });
        const jsonData = await response.json();
        console.log(jsonData);
        setPartnerships(jsonData);
        console.log(partnerships);
    }

    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000);
        fetchData();
        
    }, []);

    return (
        <>
            {spinner ? <Loader /> : 
                <div>
                    <Navbar />
                    <div className="position-relative giveHeader">
                        <h1 className="lead fs-2 fw-bolder">Donate to support our programmes <br /><span className="fs-5 fw-light">Our Details For Your Offering</span></h1>
                    </div>
                    <div className="container-lg bank row m-auto g-2 pt-4 pb-4 align-items-center">
                        <h1 className='fs-4 fw-bold pt-2 pb-2 fg'>Our  <span className='fs-3 text-danger'>Account</span> Details</h1>
                        <p className="lead fs-6 pb-4 col-12">We welcome all types of Givings, you can send in your offering or any type of giving to our Account Details provided below. <br />We appreciate your sincerity in building and spreading the word of God.</p>
                        <div className="col-lg-4 dvs">
                            <div className="p-3 rounded sided border bg-light">
                            </div>
                        </div>
                        <div className="col-lg-8 inside lead fs-6">
                            <div className="p-3 rounded border bg-light">
                                <h3 className="lead text-danger"><b className='fw-bold'>Equity Bank Uganda</b></h3>
                                <p><b>Acct Name</b>: Christos Rhema Church (Limited) By Guarantee</p>
                                <div className='row'>
                                    <div className="col-6">
                                        <b className='text-success fw-bold'>Shillings Account</b>
                                        <p>Account No: 1003200995582</p>
                                    </div>
                                    <div className='col-6'>
                                        <b className='text-success fw-bold'>Dollar Account</b>
                                        <p>Account No: 1003200995584</p>
                                    </div>
                                </div>
                                <p><b className='fw-bold'>Swift Code</b>: EQBLUGKA</p>
                                <p><b className='fw-bold'>Branch</b>: Katwe Branch</p>
                            </div>
                            <div className="p-3 rounded border bg-light mt-2">
                                <h3 className="lead fw-bolder text-danger">Mobile Money details</h3>
                                <p>MTN: 0778801702</p>
                                <p>Airtel: 0700328200</p>
                                <b className='text-success fw-bold'>Registered as Daisy Martha Nabatanzi</b>
                            </div>
                        </div>
                        <div className='d-none'>
                            <h1 className='fs-4 fw-bold pt-4 text-danger'>Our Partnerships</h1>
                            <p className='lead fs-6'>We welcome all sorts of partnerships, feel free to join any of our partnerships today. <br />God bless you</p>
                            <div className='row g-2 partnerships'>
                            {partnerships.length > 0 && (
                                partnerships.map(data => (
                                    <div key={data.id} className='col-lg-3 pdiv'>
                                        <h1 className='fs-6 fw-bold text-success'><span className='fw-bolder'>{data.name}</span></h1>
                                        <img className='w-100 rounded-top' src={domain+data.cover}/>
                                        <div className='desc'>
                                            <p className='p-2 rounded lead fw-bolder text-muted'>{truncateString(data.description, 60)}</p>
                                        </div>
                                        <div className='become'>
                                            <Link className='btn btn-sm btn-warning'>become a partner</Link>
                                        </div>
                                    </div>
                                ))
                            )}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            }
        </>
    )
}

export default Give