import React, { useState, useEffect, useRef } from 'react';
import "./css/sermonVideo.css"
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import ReactPlayer from 'react-player/lazy';
import useFetch from './FetctData';
import { domain } from './Domain';
import DynamicMetaTags from './DynamicMetaTags';
import logo from './images/aim_logo.jpeg'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
}

function SermonsVideo() {
    const [url, setUrl] = useState(`${domain}/videos`)
    const [ spinner, setSpinner ] = useState(true);
    const [currentUrl, setCurrentUrl] = useState('');
    const {data: sermons, loading, error} = useFetch(url);
    const {data: categories, loadingCategories, errorCategories} = useFetch(`${domain}/sermon-categories?type=video`);


    const pageTitle = "Christos Rhema Church | Video Sermons"
    const metaDescription = "View all our sermons and get filled with the love of the Lord"
    const imageUrl = logo

    const imageSrc = (e) => {
        console.log(e.target.getAttribute('data-src'));
        setCurrentUrl(e.target.getAttribute('data-src'));
    }

    const handleSelectedCategory = (e) => {
        let slug = e.target.getAttribute('data-src');
        setUrl(`${domain}/videos/${slug}`);
    }
      
    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000);
        console.log("we are loading the new item here")
    }, [url]);

    return (
        <>
            {spinner ? <Loader /> : 
                <div>
                    <DynamicMetaTags pageTitle = {pageTitle} metaDescription = {metaDescription} imageUrl={imageUrl}/>
                    <Navbar />
                    <div className='videos container-lg position-relative row m-auto row-cols-lg-2 row-cols-1'>
                        <div className='videoDiv col-lg-7'>
                            <div className='position-sticky top-0'>
                                <h1 className='fs-4 pt-4 fw-bold lead text-success'>Welcome to our video</h1>
                                <p className='lead fs-6'>Blessings upon you as you watch and get filled with the Holly Spirit</p>
                                {sermons &&
                                    <ReactPlayer
                                        className='react-player bg'
                                        id="react-player"
                                        //light={<img src='images/network.png' width="100%" alt='Thumbnail' />}
                                        url={currentUrl ==="" ? sermons[0]['link'] : currentUrl}
                                        width='100%'
                                        height='315px'
                                        controls={true}
                                    />
                                }
                                <h2 className='fs-4 fw-bold lead pt-4 pb-2 text-success'>Sermon Comments</h2>
                                <div className="list-group pb-4 d-none">
                                    <a href="#" className="list-group-item list-group-item-action" aria-current="true">
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1"></h5>
                                            <small className='text-muted'>3 days ago</small>
                                        </div>
                                        <p className="mb-1 lead fs-6">Some placeholder content in a paragraph.</p>
                                        <small className='text-muted'>And some small print.</small>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h1 className='fs-4 pt-4 fw-bold lead text-danger'>Other videos</h1>
                                <div className="dropdown pt-4">
                                    <button className="btn btn-success dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Select Video category
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" data-src={'all-videos'} onClick={handleSelectedCategory}>All Videos</a></li>
                                        {errorCategories && <p>{errorCategories}</p>}
                                        {loadingCategories && <p>Loading data ....</p>}
                                        {categories && (
                                            categories.map(data => (
                                                <li key={data.id}><a className="dropdown-item" data-src={data.slug} onClick={handleSelectedCategory}>{data.name}</a></li>
                                            ))
                                        )}
                                    </ul>
                                </div>
                            </div>
                            {sermons && (
                                sermons.map(data => (
                                    <div key={data.id} className='d-flex justify-content-between align-items-center border-bottom pb-2 vids pt-2'>
                                        <img src={domain+data.cover} data-src={data.link} onClick={imageSrc} className='flex-grow-1 w-25 me-3 rounded' style={{height:"100px", objectFit:"cover",objectPosition:"center"}} alt='tube_poster'/>
                                        <div className='flex-grow-1 w-50'>
                                            <h4 className='lead fs-6 p-0 m-0 fw-bold'>{data.name}</h4>
                                            <p className='text-muted p-0 m-0 lead fs-6'>{truncateString(data.description, 100)}</p>
                                            <small>posted on {data.created_at}</small>
                                        </div>
                                    </div>
                                ))
                            )}
                            
                        </div>
                    </div>
                    <Footer />
                </div>
            }
        </>
    )
}

export default SermonsVideo