import React, { useEffect, useRef, useState } from 'react';
import SimplePeer from 'simple-peer';
import io from 'socket.io-client';

function OnlineChurchStream() {
    const videoRef = useRef(null);
    const mediaStream = useRef(null);
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);
    let peer, socket;

    useEffect(() => {
        // Access the user's camera stream.
        const getCameraStream = async () => {
            try {
              const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
              mediaStream.current = stream;
              videoRef.current.srcObject = stream;

              // Connect to the signaling server
              const socket = io('http://localhost:80', {
                // Provide your custom headers here
                cors: true,
              });

              peer = new SimplePeer({ initiator: true, stream });

              //this code is for the sending of the video stream
              peer.on('signal', (data) => {
                console.log("there was a signal")
                // Send the signaling data to the receiver through the signaling server
                socket.emit('sender-signal', data);
              });
              
              peer.on('stream', (remoteStream) => {
                // This event is triggered when the remote stream is received.
                // Update the video element with the received stream.
                videoRef.current.srcObject = remoteStream;
              });
      
            } catch (error) {
              console.error('Error accessing the camera:', error);
            }
          };
    
        getCameraStream();

        // Cleanup: Stop the media stream when the component unmounts.
        return () => {
            if (mediaStream.current) {
                mediaStream.current.getTracks().forEach((track) => track.stop());
            }
            if(peer){
              console.log("there was a peer connection")
              //peer.destroy();
            }
            if (socket) {
              socket.disconnect();
            }
        };
    }, []);

    const toggleVideo = () => {
        // Toggle video track enabled state.
        const videoTrack = mediaStream.current.getVideoTracks()[0];
        if (videoTrack) {
          setVideoEnabled(!videoEnabled);
          videoTrack.enabled = !videoEnabled;
        }
      };
    
      const toggleAudio = () => {
        // Toggle audio track enabled state.
        const audioTrack = mediaStream.current.getAudioTracks()[0];
        if (audioTrack) {
          setAudioEnabled(!audioEnabled);
          audioTrack.enabled = !audioEnabled;
        }
      };

    return (
        <div className='row m-auto'>
            <div className='col-7 pt-2 ps-4'>
                <h1 className='fs-5 pt-3 text-success fw-bold'>Sermon Title <small className='text-danger d-none'>- 27th/June/2022</small></h1>
                <video
                    ref={videoRef}
                    className='rounded'
                    autoPlay // Start playing the video automatically.
                    style={{width: "100%", height: "400px", objectFit: "cover"}}
                />
                <div className='pt-2'>
                    {audioEnabled ? 
                        <button className='btn btn-danger me-2' onClick={toggleAudio}>Disable Audio</button>
                        :
                        <button className='btn btn-info me-2 text-white' onClick={toggleAudio}>Enable Audio</button>
                    }
                    {videoEnabled ? 
                        <button className='btn btn-danger me-2' onClick={toggleVideo}>Disable Video</button>
                        :
                        <button className='btn btn-success me-2 text-white' onClick={toggleVideo}>Enable Video</button>
                    }
                </div>
                <p className='lead fs-6 p-2 d-none'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
            </div>
            <div className='col-5'>
                <h1 className='fs-4 pt-4 fw-bolder' style={{color:"tomato"}}>Sermon Chat Feed</h1>
                <p className='d-none'>hello sir, how are u doing</p>
                <textarea rows={3} className='w-100 p-2 border rounded'  placeholder='please type your comment here ...'></textarea>
                <button className='btn btn-sm btn-success' disabled>send message ...</button>
            </div>
        </div>
    )
}

export default OnlineChurchStream