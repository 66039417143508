import React, { useState, useEffect } from 'react';
import "./css/annualEvents.css"
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { domain } from './Domain';
import useFetch from './FetctData';
import DynamicMetaTags from './DynamicMetaTags';
import logo from './images/aim_logo.jpeg'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
}

const emptyArray = [...Array(2)];
/*
style={{background:`url(${props.cover})`, backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"center"}}
*/

export const EventsSkeleton = () => {
    return(
        <SkeletonTheme baseColor="#ddd3" highlightColor="#5555">
            <div className='w-100'>
                <Skeleton height={10} width={"40%"} count={1} />
                <div className='d-flex gap-4 align-items-center'>
                    <div className='w-50'>
                        <Skeleton height={100} width={"100%"}  count={1} />
                    </div>
                    <div className='w-50'>
                        <div class="fs-6 text-muted pt-2 pb-2">
                            <Skeleton height={10} width={"100%"}  count={2} />
                        </div>
                        <div className='hiddenItem'>
                            <Skeleton height={10} width={"30%"}  count={1} />
                        </div>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    )
}

function Event(props){
    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = (data) => {
        setShow(true);
        setData(data)
    }
    const setSession = (e) => {
        console.log(e.target.getAttribute('data-src'));
        localStorage.setItem("category",e.target.getAttribute('data-src'));
    }
    return(
        <>
            <div className='w-100'>
                <h1 class="lead fw-bold fs-5 text-success">{props.name}</h1>
                <div className='d-block d-md-flex gap-4 align-items-center'>
                    <div className='w-100 w-md-50'>
                        <img src={props.cover} />
                    </div>
                    <div className='w-100 w-md-50'>
                        <div class="fs-6 text-muted pt-2 pb-2">
                            <div dangerouslySetInnerHTML={{ __html: truncateString(props.details, 100) }} />
                            {props.details.length > 100 && <button className='btn btn-sm m-0 p-0' onClick={()=>handleShow(props)}>continue reading</button>}
                        </div>
                        <div className='hiddenItem'>
                            <Link to={`/weekly-events/${props.slug}`} data-src={props.name} onClick={setSession} className='fw-bolder btn btn-success w-100 rounded text-white text-decoration-none'>View Attached Events</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title className='fw-bold fs-5'>{data && data.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={props.cover} className='w-100 mb-3'/>
                    <div dangerouslySetInnerHTML={{ __html: props.details }} />
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


function WeeklyEvents() {
    const [ spinner, setSpinner ] = useState(true);
    const {data: events, loading, error} = useFetch(`${domain}/events-weekly`);

    const pageTitle = "CRC Events | Annual Events"
    const metaDescription = "description"
    const imageUrl = logo
      
    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000);
    }, []);
  return (
    <>
    {spinner ? <Loader /> : 
        <div>
            <DynamicMetaTags pageTitle = {pageTitle} metaDescription = {metaDescription} imageUrl={imageUrl}/>
            <Navbar />
            <div className='container-lg pt-1 pb-1'>
                <div className="position-relative annualHeader">
                    <h1 className='fs-4 fw-bold pt-4 pb-2 fg'>Our  <span className='fs-3 text-danger'>Weekly</span> Events</h1>
                </div>
            </div>
            <div className="events container-lg pt-2 pb-4">
                {error && <p>{error}</p>}
                {loading && emptyArray.map(item => <EventsSkeleton key={item}/>)}
                {events && (
                    events.map(data => (
                        <Event 
                            key={data.id} 
                            name={data.name}
                            details={data.details}
                            slug={data.slug}
                            cover={domain+data.cover} 
                            />
                    ))
                )}
            </div>
            <Footer />
        </div>
    }
    </>
  )
}

export default WeeklyEvents