import React, { useState, useEffect, useRef } from 'react';
import { domain } from './Domain';
import "./css/staff.css"
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import useFetch from './FetctData';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const link = {
    cursor: "pointer"
}
const emptyArray = [...Array(5)];

const StaffSkeleton = () => {
    return(
        <SkeletonTheme baseColor="#ddd3" highlightColor="#5555">
            <div className="col-lg-3 col-md-4 text-center">
                <div className="card" id='card3'>
                <Skeleton class="mx-auto d-block img-thumbnail" style={{borderRadius: "100%"}} height={180} width={180} count={1} />
                <Skeleton class="p-2" height={10} width={"60%"} count={1} />
                <Skeleton height={10} width={"100%"} count={1} />
                <Skeleton height={10} width={"50%"} count={1} />
                </div>
            </div>
        </SkeletonTheme>
    )
}

function StaffMember({data, loadData}){
    const [loaded, setLoaded] = useState(false);

    const handleImageLoad = () => {
        setLoaded(true);
    };

    return(
        <div key={data.id} class="col text-center staff div">
                <div>
                    {!loaded && <Skeleton class="mx-auto d-block img-thumbnail" style={{borderRadius: "100%"}} height={180} width={180} count={1} />}
                    <img src={domain+data.img} 
                        class="mx-auto d-block img-thumbnail" 
                        alt="user_image"
                        onLoad={handleImageLoad}
                        style={{ display: loaded ? 'block' : 'none' }} 
                        loading="lazy" />
                    <figcaption class="fs-6 lead fw-bold p-2">{data.name}</figcaption>
                    <a style={link} onClick={() => loadData(data.id)} name={data.id} class="text-danger fs-6 text-decoration-none"
                        data-bs-toggle="modal" data-bs-target="#exampleModal" title={"click to view "+ data.name + " details"}>{data.position}</a>
                </div>
        </div>
    )
}

function Staff() {
    const [ spinner, setSpinner ] = useState(true);
    const {data: staff, loading, error} = useFetch(`${domain}/staff`);
    const [staffSelected, setStaffSelected] = useState();

    const loadData = (e) => {
        let filtered = staff.filter(person => person.id == e);
        setStaffSelected(filtered[0]);
    }

    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000);
        //console.log(staff);
    }, []);
    return (
        <>
        {spinner ? <Loader /> : 
            <div>
                <Navbar />
                <div className="container m-auto">
                    <h1 className='fs-4 fw-bold pt-4 pb-2 fg'>Meet Our <span className='fs-3 text-danger'>Ministries</span> Directorates</h1>
                    <p className="fs-6 lead div">We are delighted to present the dedicated and passionate individuals who form the core of our church's leadership, tirelessly guiding us on our spiritual journey. These executive members are committed to fostering a nurturing and inclusive community, where faith, compassion, and service intertwine to create a powerful impact on the lives of our congregants.</p>
                    <div className="row row-cols-1 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-5 m-auto g-2">
                    {error && <p>{error}</p>}
                    {loading && emptyArray.map(item => <StaffSkeleton key={item} />)}
                    {staff && (
                        staff.map(data => (
                            <StaffMember
                                data = {data}
                                loadData = {loadData}
                            />
                        ))
                    )}
                    </div>
                </div>


                <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    {staffSelected && 
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 fw-bolder text-danger" id="exampleModalLabel">{staffSelected.name}</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" id="modal_body">
                                <img className='w-100' src={domain+staffSelected.img} />
                                <h3 className='fw-bold pt-4 text-success fs-4'><span className='fw-bolder'>{staffSelected.position}</span></h3>
                                <p  dangerouslySetInnerHTML={{ __html: staffSelected.details }}></p>
                                
                            </div>
                            <div className="modal-footer d-none">
                                <button type="button" className="btn btn-danger d-none" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    }
                    </div>
                </div>
                <Footer />
            </div>
        }
        </>
    )
}

export default Staff