import React, {useState, useRef} from 'react'
import { domain } from './Domain';
import usePost from './PostData';

function SubscriptionsEmail({emailDetails, handleHide}) {
    console.log(emailDetails)
    const [url, setUrl] = useState()
    const [body, setBody] = useState("");
    const [subject, setSubject] = useState("");
    const [title, setTitle] = useState("");
    const [attachment, setAttachment] = useState("");
    const formDataRef = useRef();
    const {data, loading, error} = usePost(url, formDataRef.current);

    const handleSendResponse = (e) => {
        let formData = new FormData();
        formData.append("id", e);
        formData.append("subject",subject);
        formData.append("title",title);
        formData.append("body",body);
        formData.append("attachment",attachment);
        formDataRef.current = formData;
        setUrl(`${domain}/send-emails`);
    }   

    function validateForm() {
        console.log(body.length > 0 && subject.length > 0 && title.length > 0)
        return body.length > 0 && subject.length > 0 && title.length > 0;
    }

  return (
    <div key={emailDetails[0].id} className='inner'>
        <p style={{cursor:"pointer"}} onClick={() => handleHide()}><i class="fa-regular fa-circle-xmark fa-2x"></i></p>
        <label>Subject</label>
        <input type='text' onChange={(e) => setSubject(e.target.value)} className='form-control mb-2' name='subject' placeholder='email subject'/>
        <label>Title</label>
        <input type='text' onChange={(e) => setTitle(e.target.value)} className='form-control mb-2' name='title' placeholder='email title'/>
        <label>Email body</label>
        <textarea className='form-control mb-2' rows={5} onChange={(e) => setBody(e.target.value)} placeholder='Email Details'></textarea>
        <input type='file' onChange={(e) => setAttachment(e.target.files)} name='attachment' className='w-100' multiple/>
        <button className='btn btn-danger mt-3' disabled={!validateForm()} onClick={() => handleSendResponse(emailDetails[0].id)}>Send Email Message</button>
    </div>
  )
}

export default SubscriptionsEmail