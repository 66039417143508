import React from 'react'
import { Link } from 'react-router-dom'
import "./css/onlineChurchButton.css"

function OnlineChurchButton() {
  return (
    <div className='onlineBtnChurch'>
        <Link className='btn bg text-white btn-sm pt-4 pb-4' to={"/online-church"}> Online Church</Link>
    </div>
  )
}

export default OnlineChurchButton