import React, { useState, useEffect } from 'react';
import "./css/branches.css"
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import useFetch from './FetctData';
import { domain } from './Domain';
import ImageWithLoader from './ImageWithLoader';
import DynamicMetaTags from './DynamicMetaTags';
import logo from './images/aim_logo.jpeg'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
}

function Church(props){
    const [loaded, setLoaded] = useState(false);

    const handleImageLoad = () => {
        setLoaded(true);
    };
    return(
        <>
            <div className="col-lg-3 col-md-4">
                <div className="card" id='card3'>
                    {/*<ImageWithLoader src={props.image} cls_name = {'card-img-top'} alt="church-branch" height={170} />*/}
                    {!loaded && <Skeleton height={250} count={1} />}
                    <img 
                        src={props.image} 
                        onLoad={handleImageLoad}
                        style={{ display: loaded ? 'block' : 'none' }} 
                        loading="lazy" className="card-img-top" alt="church-branch"/>
                    <div className="card-body">
                        <h5 className="card-title text-success fw-bold pb-1 m-0 fs-6">{props.name} Branch</h5>
                        <div style={{fontSize: "0.875rem"}} className="card-text lead m-0" dangerouslySetInnerHTML={{ __html: truncateString(props.details, 300) }}></div>
                    </div>
                </div>
            </div>
        </>
    )
  }
const emptyArray = [...Array(4)];

const ChurchSkeleton = () => {
    return(
        <SkeletonTheme baseColor="#ddd3" highlightColor="#5555">
            <div className="col-lg-3 col-md-4">
                <div className="card" id='card3'>
                    <Skeleton height={300} width={"100%"} count={1} />
                    <div className="card-body">
                        <Skeleton height={30} width={"30%"} count={1} />
                        <Skeleton height={10} width={"100%"} count={3} />
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    )
}

function BranchChurches() {
    const [ spinner, setSpinner ] = useState(true);
    const {data: branch, loading, error} = useFetch(`${domain}/branch-churches`);

    const pageTitle = "CRC Branch Churches | Uganda"
    const metaDescription = "description"
    const imageUrl = logo

    useEffect(() => {
        setTimeout(() => setSpinner(false), 500);
        
    }, []);
  return (
    <>
        {spinner ? <Loader /> : 
            <div>
                <DynamicMetaTags pageTitle = {pageTitle} metaDescription = {metaDescription} imageUrl={imageUrl}/>
                <Navbar />
                <div className="container-lg text-danger pt-3 Header">
                    <h1 className='fs-4 fw-bold pt-2 pb-2 fg'>Our Branch  <span className='fs-2 text-danger'>Churches</span> in Uganda</h1>
                </div>
                <div className="container-lg pt-2 pb-4 row m-auto g-2">
                    <p className="lead fs-6 text-muted col-12">We have a number of churches all over the country. Below is a list of branch churches with the corresponding Leaders</p>
                    {error && <p>{error}</p>}
                    {loading && emptyArray.map(item => <ChurchSkeleton key={item} />)}
                    {branch && (
                        branch.map(data => (
                            <Church 
                                key={data.id} 
                                name={data.name}
                                image={domain+data.cover}
                                details={data.details}
                            />
                        ))
                    )}
                </div>
                <Footer />
            </div>
        }
    </>
  )
}

export default BranchChurches