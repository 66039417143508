import React, {useState, useRef, useEffect} from 'react'
import "./css/requests.css"
import useFetch from './FetctData';
import DetailsPrayerrequest from './DetailsPrayerrequest';
import { domain } from './Domain';


function PrayerRequests() {
    const [url, setUrl] = useState(`${domain}/prayer-requests`)
    const {data: requests, loading, error} = useFetch(url);
    const [details, setDetails] = useState(null);
    const overlay = useRef();
    //const [{ min, max }, setRangeValues] = useState({ min: '', max: '' });

    const handleRequests = (e) => {
        e.preventDefault();
        //sample of the logic to performa a filter
        let search = e.target.request.value;    
        setUrl(`${domain}/prayer-requests?search=${search}`);
    }

    const handleFiltering =(e)=>{
        console.log(e.target.value.length);
        if(e.target.value.length === 0){
            setUrl(`${domain}/prayer-requests`);
        }

    }

    const handleDate = (e) => {
        e.preventDefault();
        //sample of the logic to performa a filter
        let start_date = e.target.start_date.value;  
        let end_date = e.target.end_date.value; 
        if(start_date !== '' && end_date !== ""){
            setUrl(`${domain}/prayer-requests?start_date=${start_date}&end_date=${end_date}`);   
        }else{
            alert("please provide a date range");
        }
        
        
    }

    const handleDetails = (e) => {
        overlay.current.style.display = "block"
        //const filtered = requests.filter((request) => request.id=== e);
        fetch(`${domain}/prayer-requests/${e}`)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            setDetails(data);
        })
        .catch(err =>{
            alert(err.message);
        })
        
    }

    const handleHide = (e) => {
        setDetails(null);
        overlay.current.style.display = "none";
    }

    const handleDownload = () => {
        fetch(`${domain}/download?type=prayer-requests`)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            console.log(data);
            const jsonContent = JSON.stringify(data);
            const blob = new Blob([jsonContent], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'data.json';
            link.click();
        })
        .catch(err =>{
            alert(err.message);
        })
    }

  return (
    <div>
        <div className='filter'>
            <form onSubmit={handleRequests}>
                <input type='text' onChange={handleFiltering} name ="request" placeholder='search prayer requests'/>
                <button>search requests</button>
            </form>
            <form onSubmit={handleDate}>
                <div>
                    <label>start date</label>
                    <input type='date' name ="start_date"/>
                </div>
                <div>
                    <label>end date</label>
                    <input type='date' name ="end_date"/>
                </div>
                <div>
                    <label>filter requests</label>
                    <button>get requests</button>
                </div>
            </form>
        </div>
        <div className='requests'>
            <h1 className='fs-4 fw-bold'><span className='fw-bolder text-secondary'>Availale Prayer Requests</span> <button className='btn btn-outline-primary btn-sm' onClick={handleDownload}>Download</button></h1>
            {error && <p>{error}</p>}
            {loading && <p>Loading data ....</p>}
            {requests && 
            <table>
                <thead>
                    <tr>
                        <th style={{width: '5%'}}>#</th>
                        <th style={{width: '20%'}}>Name</th>
                        <th style={{width: '40%'}}>Request</th>
                        <th style={{width: '20%'}}>Date</th>
                        <th style={{width: '15%'}}>Request <i className="fa-solid fa-hands-praying"></i> </th>
                    </tr>
                </thead>
                <tbody>
                    {requests.map(request => {
                        return(
                            <tr key={request.id}>
                                <td>{request.id}</td>
                                <td>{request.name}</td>
                                <td>{request.request}</td>
                                <td>{request.created_at}</td>
                                <td onClick={() => handleDetails(request.id)}>View Request</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            }
        </div>
        <div className='overlay' ref={overlay}>
            {details && <DetailsPrayerrequest prayerRequest = {details} handleHide={handleHide} />}
        </div>
    </div>
  )
}

export default PrayerRequests