import React, { useState, useEffect } from 'react';
import { domain } from './Domain';
import "./css/contact.css"
import Loader from './Loader';
import Navbar from './Navbar';
import Footer from './Footer';
import DynamicMetaTags from './DynamicMetaTags';
import logo from './images/aim_logo.jpeg'

function Contact() {
    const [ spinner, setSpinner ] = useState(true);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const pageTitle = "CRC | Contact Us "
    const metaDescription = "description"
    const imageUrl = logo

    const sendMessage = () => {
        let form = document.getElementById("contact_form");
        form.onsubmit = function (event) {
            event.preventDefault();
            let data = new FormData(form);
            document.getElementById("responses").innerHTML = "sending ...";
            const requestOptions = {
                method: 'POST',
                credentials: "omit",
                body: data
            };
            setLoading(true);
            fetch(`${domain}/contact-us`, requestOptions)
            .then(res => {
                if(!res.ok){
                    throw Error(res.statusText);
                }
                return res.json();
            })
            .then(data =>{
                document.getElementById("responses").innerHTML = data['message'];
                setTimeout(function(){
                    document.getElementById("responses").innerHTML = "";
                },3000);
                setData(data);
                setLoading(false);
                setError(null);
                form.reset();
            })
            .catch(err =>{
                setError(err.message);
                setLoading(false);
            })
        }
    }
    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000);
        document.title = "AIM-CRC | Contact-us";
        
    }, []);
    return (
        <>  
            {spinner ? <Loader /> : 
                <div>
                    <DynamicMetaTags pageTitle = {pageTitle} metaDescription = {metaDescription} imageUrl={imageUrl}/>
                    <Navbar />
                    <div className="row m-auto container pb-4 pt-4 lead">
                        <div className="col-lg-7 div">
                            <h1 className='fs-4 fw-bold pt-2 pb-2 fg'>Contact  <span className='fs-3 text-danger'>Us</span> Today</h1>
                            <div className="d-flex justify-content-start align-items-center">
                                <i className="fa-solid fs-6 fa-location-dot bg text-white p-2 rounded me-3"></i>
                                <address className="fs-6">
                                    <span className='text-success fw-bold'>Address</span><br />
                                    <span>Christos Rhema Church</span>,<br />
                                    <span>15 Kaminanda Ln, Kampala</span>, <br />
                                    <span>P.O.Box **** Kampala</span>
                                </address>
                            </div>
                            <div className="d-flex justify-content-start align-items-center">
                                <i className="fa-solid fs-6 fa-phone bg text-white p-2 rounded me-3"></i>
                                <address className="fs-6">
                                    <span className='text-success fw-bold'>phone</span><br />
                                    <span>+256-393-206175</span> <br />
                                    <span>+256-706-905656</span>

                                </address>
                            </div>
                            <div className="d-flex justify-content-start align-items-center">
                                <i className="fa-solid fs-6 fa-envelope bg text-white p-2 rounded me-3"></i>
                                <address className="fs-6">
                                    <span className='text-success fw-bold'>Email</span><br />
                                    <a href="mailto:info@christosrhema" className='text-decoration-none text-dark'>info@christosrhema.org</a> <br />
                                    <a href="mailto:christosrhemachurch@gmail.com" className='text-decoration-none text-dark'>christosrhemachurch@gmail.com</a>
                                </address>
                            </div>
                            <iframe className="mt-4" width="100%" height="300px" frameborder="0" style={{border:0, background: 'rgba(3, 73, 134, 0.5'}}
                                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBGY-aKP5KQbjJbf2B2mN6u9909uI5iv50&amp;q=Christos Rhema Church, kampala"
                                allowfullscreen=""></iframe>
                        </div>
                        <div className="col-lg-5 div">
                            <h1 className='fs-4 fw-bold pt-2 pb-2 fg'>Send Us a  <span className='fs-3 text-danger'>Message</span></h1>
                            <p className="lead fs-6">For any inquiries about Christos Rhema Church, please send us a
                                message. We repond within 24hrs period.</p>
                            <form action="/contact-us/" method="post" id='contact_form'>

                                <p className="text-success fs-6 fw-bold" id='responses'></p>
                                
                                <div className="form-floating mb-3">
                                    <input type="text" name="name" required className="form-control" id="floatingInput" placeholder="Enter your name"/>
                                    <label for="floatingInput" className="text-muted fs-6">Fullname</label>
                                </div>
                                <div className="form-floating mb-3 d-none">
                                    <input type="tel" className="form-control" id="floatingInput" placeholder="Enter your name"/>
                                    <label for="floatingInput" className="text-muted fs-6">Phone number</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="email" name="email" required className="form-control" id="floatingInput" placeholder="name@example.com"/>
                                    <label for="floatingInput" className="text-muted fs-6">Email address</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <textarea name="message" required className="form-control"
                                        placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                                    <label for="floatingTextarea" className="text-muted fs-6">Message</label>
                                </div>
                                <input type="checkbox" name="subscribe"/> <span className="text-muted fs-6"> subsbscribe to our news
                                    letter</span>
                                
                                {! loading && <button className="btn bg text-white w-100 p-2 mt-2" onClick={sendMessage}>Send Message <i class="fa-solid fa-paper-plane"></i></button>}
                                {loading && <button className="btn btn-success text-white w-100 p-2 mt-2" disabled>Sending Message ...</button>}
                            </form>
                        </div>
                    </div>
                    <Footer />
                </div>
            }
        </>
    )
}

export default Contact