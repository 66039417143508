import React, { useEffect, useState, useRef } from 'react'
import './css/notifications.css'
import useFetch from './FetctData';
import { domain } from './Domain';

const NotificationSet = ({handleHide, refresh, setRefresh}) => {
    const [body, setBody] = useState("");
    const [title, setTitle] = useState("");
    const formDataRef = useRef();
    const [isPending, setIsPending] = useState(false);

    const handleSendResponse = (e) => {
        setIsPending(true);
        let formData = new FormData();
        formData.append("title",title);
        formData.append("notification",body);
        formDataRef.current = formData;
        fetch(`${domain}/notifications`,{
            method: "POST",
            mode: "cors", // no-cors, *cors, same-origin,
            //credentials: 'include',
            body: formData,
        })
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            console.log(data);
            setIsPending(false);
            setRefresh(!refresh);
        })
        .catch(err =>{
            console.log(err.message);
        })
    }   

    function validateForm() {
        return body.length > 0 && title.length > 0;
    }

    return(
        <div className='inner'>
            <p style={{cursor:"pointer"}} onClick={() => handleHide()}><i className="fa-regular fa-circle-xmark fa-2x"></i></p>
            <label>Notification Title</label>
            <input type='text' onChange={(e) => setTitle(e.target.value)} className='form-control mb-2' name='title' placeholder='email title'/>
            <label>Notification body</label>
            <textarea className='form-control mb-2' rows={5} onChange={(e) => setBody(e.target.value)} placeholder='Email Details'></textarea>
            {! isPending && <button className='btn btn-danger mt-3' disabled={!validateForm()} onClick={handleSendResponse}>Send Out Notification</button>}
            {isPending && <button className='btn btn-danger mt-3' disabled>Sending Notification ...</button>}
        </div>
    )
}
function Notifications() {
    const [url, setUrl] = useState(`${domain}/notifications`)
    let {data:notifications, loading, error, refresh, setRefresh} = useFetch(url);
    const [isPending, setIsPending] = useState(false);
    const overlay = useRef();

    const handleHide = (e) => {
        overlay.current.style.display = "none";
    }

    const handleSearch = (e) => {
        e.preventDefault();
        const search = e.target.search.value;
        //sample of the logic to performa a filter
        //const filtered = notifications.filter(notification => notification.title.includes(search) || notification.notification.includes(search))
        //console.log(filtered);
        setUrl(`${domain}/notifications?search=${search}`);
    }

    const handleSetNotifications = () => {
        overlay.current.style.display = "block";
    }

    const handleDelete = (e) => {
        setIsPending(true);
        fetch(`${domain}/notifications/${e}`,{
            method: "DELETE",
            mode: "cors", // no-cors, *cors, same-origin,
            //credentials: 'include',
        })
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            console.log("deleted successfully!")
            setIsPending(false);
            setRefresh(!refresh);
        })
        .catch(err =>{
            console.log(err.message);
        })
    }

    const handleFiltering =(e)=>{
        console.log(e.target.value.length);
        if(e.target.value.length === 0){
            setUrl(`${domain}/notifications`);
        }

    }

    return (
        <div>
            <div className='filter_notifications'>
                <button className='btn btn-primary' onClick={handleSetNotifications}>Add Notification</button>
                <form onSubmit={handleSearch}>
                    <input type='text' name ="search" onChange={handleFiltering} placeholder='search notifications'/>
                    <button>search notifications</button>
                </form>
            </div>
            <div className='requests'>
                <h1 className='fs-4 fw-bold'><span className='fw-bolder text-secondary'>Broadcasted Notifications <button className='btn btn-outline-primary btn-sm'>Download</button></span></h1>
                {error && <p>{error}</p>}
                {loading && <p>Loading data ....</p>}
                {notifications && 
                <>
                    {notifications.map(notification => {
                        return(
                            <div className='border-bottom pb-3 pt-3' key={notification.id}>
                                <h4 className='lead fw-bold fs-5 text-success'>{notification.title}</h4>
                                <p className='lead fs-6' dangerouslySetInnerHTML={{ __html: notification.notification }}></p>
                                {! isPending && <button className='btn btn-sm btn-danger text-white mb-2' onClick={() => handleDelete(notification.id)}>Delete</button>} <br />
                                {isPending && <button className='btn btn-sm btn-danger text-white mb-2'>Deleting Notification ...</button>}
                                <small className='text-muted'>{notification.created_at}</small>
                            </div>
                        )
                    })}
                </>
                }
            </div>
            <div className='overlay' ref={overlay}>
                {<NotificationSet handleHide={handleHide} refresh={refresh} setRefresh={setRefresh}/>}
            </div>
        </div>
    )
}

export default Notifications