import React, { useState, useEffect, useRef } from 'react';
import Loader from './Loader';
import icon from './images/aim_logo.jpeg';
import cover from './images/IMG_20230509_164933_384.jpg'
import { Link } from 'react-router-dom';
import './css/register.css'
import { domain } from './Domain';

function Register(){
    const [isPending, setIsPending] = useState(false);
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [passmsg, setPassmsg] = useState("");
    const regRefSuccess = useRef()

    const [ spinner, setSpinner ] = useState(true);

    useEffect(() => {
        setTimeout(() => setSpinner(false), 500);
    }, []);

    function validateForm() {
        return email.length > 0 && password.length > 0 && username.length > 0 && fname.length > 0 && lname.length > 0 && password === password2;
    }

    function checkpassword() {
        if(password === password2 && password.length > 0 && password2.length > 0){
            setPassmsg("");
        }else{
            setPassmsg("passwords do not match");
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsPending(true);
        regRefSuccess.current.style.display = "block";
        regRefSuccess.current.textContent = "Sending registration data ..."
        let form = e.target;
        let data = new FormData(form);
        const requestOptions = {
            method: 'POST',
            cors: "cors",
            body: data
        };
        fetch(`${domain}/accounts/register`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            //console.log(data);
            form.reset();
            regRefSuccess.current.textContent = data['message']
            setTimeout(function(){
                regRefSuccess.current.textContent = '';
                regRefSuccess.current.style.display = "none";
            },5000);
            setIsPending(false);
        })
        .catch((err) => console.log(err))
    }

    return(
        <>  
        {spinner ? <Loader /> :
        <div className='row m-auto  align-items-center' style={{padding: '0 5%'}}>
            <div className='col-md-7'>
                <img className='logos' src={icon} />
                <h1 className='fg fw-bolder' id='h1'>Blessed to Have You Here: <span className='text-success'>Register for Our Church Services</span></h1>
                <p className='lead fs-6'>Remained connected with Pr. David Omongole Through Our online Church Services</p>
                
                <p className='lead fs-6'><q>Welcome to Our Church! We are delighted that you're considering becoming a part of our loving and supportive community. Please take a moment to register below, and let us walk together on this spiritual journey</q></p>
                <p className='lead fs-6'><q>At our church, we celebrate faith, hope, and love. Your presence matters to us. Register now to connect with fellow believers, engage in uplifting worship, and find a place of belonging</q></p>
                <img src={cover} className='w-100'/>
                <figcaption className='fw-bolder'>CRC Members</figcaption>
            </div>
            <div className='col-md-5 mt-4 forms'>
                    <h1 className='fs-4 pt-4 pb-2 lead fw-bolder text-danger'><span className='fw-bold'>CRC User Account Information</span></h1>
                    <p className='lead fs-6'>Your presence matters to us. Kindly share your information with us to help us serve you better.</p>
                    <p ref={regRefSuccess} className='text-success'></p>
                    <form onSubmit={handleSubmit}>
                        <div className="input-group mb-3">
                            <span className="input-group-text"><i className="fa-solid fa-file-signature"></i></span>
                            <input type="text" name='first_name' aria-label="First name" value={fname} onChange={(e) => setFname(e.target.value)} placeholder='first name' className="form-control"/>
                            <input type="text" name='last_name' aria-label="Last name" value={lname} onChange={(e) => setLname(e.target.value)} placeholder='lastname' className="form-control"/>
                        </div>
                        <div className="input-group mb-3">
                            <span class="input-group-text"><i className="fa-solid fa-user-secret"></i></span>
                            <input type="text" name='username' class="form-control" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" aria-label="Username"/>
                        </div>
                        <div className="input-group mb-3">
                            <span class="input-group-text"><i className="fa-solid fa-envelope"></i></span>
                            <input type="text" name='email' class="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" aria-label="Email"/>
                        </div>
                        <div class="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><i class="fa-solid fa-mobile-screen-button"></i></span>
                            <input type="text" name='contact' className="form-control" placeholder="Phone number" aria-label="Username" aria-describedby="basic-addon1"/>
                        </div>
                        <div class="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><i class="fa-solid fa-location"></i></span>
                            <input type="text" name='address' className="form-control" placeholder="Enter your Address" aria-label="Address" aria-describedby="basic-addon1"/>
                        </div>
                        <div class="input-group mb-3">
                            <input type="file" name='image' className="form-control" aria-label="Address" aria-describedby="basic-addon1"/>
                        </div>
                        <div className="input-group mb-3">
                            <span class="input-group-text"><i className="fa-solid fa-lock"></i></span>
                            <input type="password" name='password' class="form-control" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" aria-label="Password"/>
                            <input type="password" name='password2' aria-label="password" value={password2} onChange={(e) => setPassword2(e.target.value)} onBlur={checkpassword} placeholder='confirm password' className="form-control"/>
                        </div>
                        <div className="form-check form-switch mb-0">
                            <input className="form-check-input" name='subscribe' type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                            <label className="form-check-label" style={{fontSize: "0.875rem"}} for="flexSwitchCheckDefault">Subscribe To Our Alerts</label>
                        </div>
                        <small className='text-danger'>{passmsg}</small>
                        
                        {! isPending && <button className='btn btn-success w-100 mb-3 mt-3' type="submit" disabled={!validateForm()}>Submit Registration <i class="fa-solid fa-right-to-bracket"></i></button>}
                        {isPending && <button className='btn btn-success w-100 mb-3 mt-3' disabled>Submiting Registration Details <i class="fa-solid fa-right-to-bracket"></i></button>}
                        <div className='d-flex justify-content-end'>
                            <small className='pe-3'>Already have an account ?</small>
                            <small><Link to={"/accounts/login"} className='text-danger fw-bold text-decoration-none' >Login Here</Link></small>
                        </div>
                        <hr />
                        <div className='d-flex justify-content-between border rounded p-3'>
                            <Link className='text-decoration-none'><small>Terms & conditons</small></Link>
                            <Link className='text-decoration-none'><small>Privacy Policy</small></Link>
                            <Link className='text-decoration-none'><small>Security</small></Link>
                        </div>
                    </form>
                
            </div>
        </div>
        }</>
    )   
}
export default Register