import React from 'react';
import { Helmet } from 'react-helmet';

const DynamicMetaTags = ({pageTitle, metaDescription, imageUrl}) => {

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={imageUrl} />
      </Helmet>

      {/* Your page content goes here */}
    </div>
  );
};

export default DynamicMetaTags;
