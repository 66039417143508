import { useEffect, useState } from "react";

const useFetch = (url) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(false);

    useEffect(()=>{
        const abortCon = new AbortController();
        fetch(url, {signal: abortCon.signal})//associate each fetch request with the abort controller
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            setData(data);
            setLoading(false);
            setError(null);
        })
        .catch(err =>{
            if(err.name === "AbortError"){
                console.log("fetch aborted");
            }else{
                setError(err.message);
                setLoading(false);
            }
        })
        console.log("we are refrshing again");
        return () => {
            console.log("clean up function execution running!")
            abortCon.abort();
        }
    },[url, refresh])
    return {data, loading, error, refresh, setRefresh}
}

export default useFetch


/*
    async function fetchData() {
        const response = await fetch("http://127.0.0.1:8000/events-annual",{
            mode: "cors", // no-cors, *cors, same-origin,
            //credentials: 'include',
        });
        const jsonData = await response.json();
        console.log(jsonData);
        setEvents(jsonData);
    }
*/