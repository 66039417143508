import React, { useState, useEffect } from 'react';
import Loader from './Loader';
import Navbar from './Navbar';
import MainOne from './MainOne';
import MainTwo from './MainTwo';
import MainThree from './MainThree';
import MainFour from './MainFour';
import Footer from './Footer';
import { CarouselSlidder } from './Carousel';
import OnlineChurchButton from './OnlineChurchButton';
import DynamicMetaTags from './DynamicMetaTags';
import logo from './images/aim_logo.jpeg'

function EntryPage() {
    const [ spinner, setSpinner ] = useState(true);
    const pageTitle = "Christos Rhema Church - Homepage"
    const metaDescription = "The description of this page"
    const imageUrl = logo
    // It will be executed before rendering
    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000)
    }, []);
    // [] means like componentDidMount
    return (
        <>
            {spinner ? <Loader /> : 
                <div>
                    <DynamicMetaTags pageTitle = {pageTitle} metaDescription = {metaDescription} imageUrl={imageUrl}/>
                    <Navbar />
                    <OnlineChurchButton />
                    <CarouselSlidder />
                    <MainOne />
                    <MainTwo />
                    <MainThree />
                    <MainFour />
                    <Footer />
                </div>
            }
        </>
    )
}

export default EntryPage