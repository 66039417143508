import React, {useState, useEffect, useRef} from 'react'
import useFetch from './FetctData';
import { domain } from './Domain';
import SubscriptionsEmail from './SubscriptionsEmail';

function Subscriptions() {
    const [url, setUrl] = useState(`${domain}/subscriptions`)
    const [details, setDetails] = useState(null);
    const {data: emails, loading, error} = useFetch(url);
    const overlay = useRef();

    const handleRequests = (e) => {
        e.preventDefault();
        let search = e.target.email.value;    
        setUrl(`${domain}/subscriptions?search=${search}`);
    }

    const handleFiltering =(e)=>{
        console.log(e.target.value.length);
        if(e.target.value.length === 0){
            setUrl(`${domain}/subscriptions`);
        }

    }

    const handleDate = (e) => {
        e.preventDefault();
        let start_date = e.target.start_date.value;  
        let end_date = e.target.end_date.value; 
        if(start_date !== '' && end_date !== ""){
            setUrl(`${domain}/subscriptions?start_date=${start_date}&end_date=${end_date}`);   
        }else{
            alert("please provide a date range");
        }
    }

    const handleDetails = (e) => {
        overlay.current.style.display = "block"
        const filtered = emails.filter((email) => email.id=== e);
        setDetails(filtered);
    }

    const handleHide = (e) => {
        setDetails(null);
        overlay.current.style.display = "none";
    }
  return (
    <div>
        <div className='filter'>
            <form onSubmit={handleRequests}>
                <input type='text' onChange={handleFiltering} name ="email" placeholder='search email address'/>
                <button>search subscriptions</button>
            </form>
            <form onSubmit={handleDate}>
                <div>
                    <label>start date</label>
                    <input type='date' name ="start_date"/>
                </div>
                <div>
                    <label>end date</label>
                    <input type='date' name ="end_date"/>
                </div>
                <div>
                    <label>filter subscriptions</label>
                    <button>filter by dates</button>
                </div>
            </form>
        </div>
        <div className='requests'>
            <h1 className='fs-4 fw-bold'><span className='fw-bolder'>Current Email Subscribers</span></h1>
            <button className='btn btn-danger mb-2 text-white'>Send Bulk Mail</button>
            {error && <p>{error}</p>}
            {loading && <p>Loading data ....</p>}
            {emails && 
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Email</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {emails.map(request => {
                        return(
                            <tr key={request.id}>
                                <td>{request.id}</td>
                                <td>{request.email}</td>
                                <td>{request.created_at}</td>
                                <td onClick={() => handleDetails(request.id)}>Send Email</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            }
        </div>
        <div className='overlay' ref={overlay}>
            {details && <SubscriptionsEmail emailDetails = {details} handleHide={handleHide} />}
        </div>
    </div>
  )
}

export default Subscriptions